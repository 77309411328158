import React, { FunctionComponent } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';
import cx from 'classnames';
import { useAuth } from '../../Providers';
import { useLocation } from 'react-router-dom';
import { AuthButton } from '../../Components/AuthButton';
import { NavLink } from '../helpers';
import { routePaths } from '../../constants';
import { SignupButton } from '../../Components/SignupButton';

interface UseStylesProps {
  width: number;
}

const useStyles = makeStyles((theme) => ({
  box: {
    position: 'fixed',
    left: 0,
    top: 0,
    width: (props: UseStylesProps) => props.width,
    height: '100%',
    transition: 'transform 0.25s ease',
    boxShadow: theme.shadows[2],
    backgroundColor: '#fff',
    zIndex: 1100,
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
  },

  wrapper: {
    marginTop: 100,
  },

  navSection: {
    listStyle: 'none',
    marginLeft: 0,
    paddingLeft: 0,
    '& > li + li': {
      marginTop: theme.spacing(4),
    },
  },
}));

export interface PusherProps {
  width: number;
  className: string;
}

/**
 * maintain query string parameters when user is navigation
 */
export const Pusher: FunctionComponent<PusherProps> = ({
  className,
  width,
}) => {
  const classes = useStyles({ width });

  const { isLoggedIn, user } = useAuth();
  const query = useLocation().search;

  return (
    <Box className={cx(className, classes.box)}>
      {/* takes the whole screen */}
      <Box flex={1} overflow={'auto'} mt={12} component={'nav'} px={3}>
        {/* section */}
        {isLoggedIn && (
          <Box component={'ul'} className={classes.navSection}>
            {user.dashboard_type !== 'ONLY_AGGREGATE' && (
              <Typography component={'li'}>
                <NavLink
                  to={routePaths.userMetric.constructConcretePath(
                    user.org_id,
                    user.user_id,
                    'all',
                    query
                  )}
                >
                  User Charts
                </NavLink>
              </Typography>
            )}

            <Typography component={'li'}>
              <NavLink
                to={routePaths.orgMetric.constructConcretePath(
                  user.org_id,
                  'all',
                  query
                )}
              >
                Org Charts
              </NavLink>
            </Typography>

            <Typography component={'li'}>
              <NavLink
                to={routePaths.orgSettings.constructConcretePath(user.org_id)}
              >
                Org Settings
              </NavLink>
            </Typography>

            <Typography component={'li'}>
              <NavLink
                to={routePaths.orgUsers.constructConcretePath(user.org_id)}
              >
                Org Users
              </NavLink>
            </Typography>

            <Typography component={'li'}>
              <NavLink to={routePaths.help.path}>Help</NavLink>
            </Typography>

            <Typography component={'li'}>
              <NavLink
                to="https://www.healthzilla.ai/dashboard-quick-guide"
                target="_guide"
              >
                User Guide
              </NavLink>
            </Typography>

            <Typography component={'li'}>
              <NavLink
                to="https://www.healthzilla.ai/support"
                target="_support"
              >
                Support
              </NavLink>
            </Typography>
          </Box>
        )}
      </Box>

      {/* login section */}
      <Box display={'flex'} pl={6} pb={3}>
        <AuthButton />

        <Box ml={3}>
          <SignupButton />
        </Box>
      </Box>
    </Box>
  );
};
