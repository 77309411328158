import { useEffect, useRef } from 'react';
import { getOrgTags } from '../../lib';
import { useForceUpdate } from '../useForceUpdate';

export const useOrgTags = (orgId: string) => {
  const stateRef = useRef<{
    loadState: 'loading' | 'loaded' | 'error';
    orgTags: string[];
  }>({
    loadState: 'loading',
    orgTags: [],
  });

  const forceUpdate = useForceUpdate();

  const loadTags = () => {
    if (stateRef.current.loadState !== 'loading') {
      stateRef.current.loadState = 'loading';
      forceUpdate();
    }

    return getOrgTags(orgId)
      .then((orgTags) => {
        stateRef.current = {
          ...stateRef.current,
          orgTags,
          loadState: 'loaded',
        };
      })
      .catch((ex) => {
        stateRef.current.loadState = 'error';
      })
      .finally(() => {
        return forceUpdate();
      });
  };

  useEffect(() => {
    loadTags();
  }, []);

  const { loadState, orgTags } = stateRef.current;
  return {
    loadState,
    orgTags,

    refreshOrgTags: () => loadTags(),

    setOrgTags: (orgTags: string[]) => {
      stateRef.current.orgTags = orgTags;
      return forceUpdate();
    },
  };
};
