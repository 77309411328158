import React, { FunctionComponent } from 'react';
import Chartist from 'chartist';
import ChartistGraph from 'react-chartist';

import 'chartist-plugin-tooltips';
import 'chartist-plugin-axistitle';
import 'chartist/dist/scss/chartist.scss';
import { MetricChartConfig } from '../../constants';

import '../../lib/plugins';
import { chartistPluginAddExtraClassNames } from '../../lib/plugins';

export interface ChartOptions {
  yAxisLabel: string;
}

const getChartOptions = (options?: MetricChartConfig) => {
  return {
    showArea: true,

    fullWidth: true,

    height: '405px',

    axisY: {
      ...(options.yAxisExtraProps || {}),
      labelInterpolationFnc: (value) => {
        return options.yAxisInterpolationFunc?.(value) || value;
      },
      showGrid: true,
    },

    // Options for X-Axis
    axisX: {
      ...(options.xAxisExtraProps || {}),
      labelInterpolationFnc: (value, index) => {
        return options.xAxisInterpolationFunc?.(value, index) || value;
      },
      showGrid: true,
    },

    plugins: [
      Chartist.plugins.tooltip({
        class: '',
        transformTooltipTextFnc: (value) => value,
      }),

      // our custom plugin
      chartistPluginAddExtraClassNames(Chartist, options),

      // Chartist.plugins.ctAxisTitle({
      //   axisY: {
      //     axisTitle: options.yAxisTitle || '', // ct_series.yAxisTitle,
      //     axisClass: 'ct-axis-title',
      //     offset: {
      //       x: 0,
      //       y: -1,
      //     },
      //     flipTitle: false,
      //   },
      // }),
    ],
  };
};

export interface ChartData {
  labels: string[];
  series: Array<any[]>;
  type?: 'Line' | 'Bar';
}

export interface ChartProps extends ChartData {
  chartOptions: MetricChartConfig;
  className?: string;
}

export const Chart: FunctionComponent<ChartProps> = ({
  labels,
  series,
  chartOptions,
  type = 'Line',
  className,
}) => {
  return (
    <ChartistGraph
      className={className}
      type={type}
      data={{ labels, series: series }}
      options={getChartOptions(chartOptions)}
      // listener={Animation()}
    />
  );
};
