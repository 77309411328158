import { formatDateParameters } from '../../utils';

export type DataSet = any[];

const getDaysInMonth = function (year: number, month: number) {
  // Here January is 1 based
  //Day 0 is the last day in the previous month

  const date = new Date(year, month, 1);
  const newDate = new Date(date.getTime() - 24 * 60 * 60 * 1000);

  // console.log(year, month, 1, date.toDateString(), newDate.toDateString());

  // return new Date(date.getTime() - 24 * 60 * 60 * 1000).getDate();
  return new Date(year, month, 0).getDate();
};

/**
 * it basically fills up missing data, with zeros
 * @param year
 * @param month
 * @param startDay
 */
const getDataForDaysInAMonth = (year: number, month: number, startDay = 1) => {
  let result = [];
  const numberOfDaysInMonth = getDaysInMonth(year, month);

  while (startDay <= numberOfDaysInMonth) {
    result.push([formatDateParameters(year, month, startDay), null]);
    startDay += 1;
  }
  return result;
};

const insertDataPlusMissingData = (
  result: DataSet[],
  prevData: DataSet,
  currentData: DataSet
) => {
  // colleact year, month, and days
  const [, prevDataYear, prevDataMonth, prevDataDay] = /(\d+)-(\d+)-(\d+)/.exec(
    prevData[0]
  );
  const [
    ,
    currentDataYear,
    currentDataMonth,
    currentDataDay,
  ] = /(\d+)-(\d+)-(\d+)/.exec(currentData[0]);

  // parse to ints
  const prevDataYearInt = parseInt(prevDataYear, 10);
  const prevDataMonthInt = parseInt(prevDataMonth, 10);
  const prevDataDayInt = parseInt(prevDataDay, 10);

  const currentDataYearInt = parseInt(currentDataYear, 10);
  const currentDataMonthInt = parseInt(currentDataMonth, 10);
  const currentDataDayInt = parseInt(currentDataDay, 10);

  // level up year, get left over days in the prev month,
  // get days for the remaining month, increment year, and go again
  if (currentDataYearInt > prevDataYearInt) {
    result.push(
      ...getDataForDaysInAMonth(
        prevDataYearInt,
        prevDataMonthInt,
        prevDataDayInt + 1
      )
    );
    let startIterationMonthInt = prevDataMonthInt + 1;
    while (startIterationMonthInt++ <= 12) {
      result.push(
        ...getDataForDaysInAMonth(prevDataYearInt, startIterationMonthInt, 1)
      );
    }
    const newPreviousData: DataSet = [
      formatDateParameters(prevDataYearInt + 1, 1, 1),
      null,
    ];
    result.push(newPreviousData);
    return insertDataPlusMissingData(result, newPreviousData, currentData);
  }

  // now that we are in the same year, level up the months
  // get left over days in the prev month, get the days for the remaing month before the current month, and go again
  if (currentDataMonthInt > prevDataMonthInt) {
    result.push(
      ...getDataForDaysInAMonth(
        prevDataYearInt,
        prevDataMonthInt,
        prevDataDayInt + 1
      )
    );

    let startIterationMonthInt = prevDataMonthInt;
    while (++startIterationMonthInt < currentDataMonthInt) {
      result.push(
        ...getDataForDaysInAMonth(prevDataYearInt, startIterationMonthInt, 1)
      );
    }

    const newPreviousData: DataSet = [
      formatDateParameters(prevDataYearInt, currentDataMonthInt, 1),
      null,
    ];
    result.push(newPreviousData);
    return insertDataPlusMissingData(result, newPreviousData, currentData);
  }

  // they are in the same year, and the same month. lets fill up the days
  let startInterationDayInt = prevDataDayInt;
  while (++startInterationDayInt <= currentDataDayInt) {
    result.push([
      formatDateParameters(
        prevDataYearInt,
        prevDataMonthInt,
        startInterationDayInt
      ),
      null,
    ]);
  }
  result[result.length - 1] = currentData;

  return result;
};

// data format Array<[dateString, numericValue]>
export const interleaveData = (
  data: Array<DataSet>,
  from: string,
  to: string
) => {
  // we are being sent time portion in the data, which we basically do not need
  data = data.map((current) => {
    return [current[0].split(' ')[0], current[1]];
  });

  // make sure our data point starts from the given from and to values
  from = from.split(' ')[0];
  to = to.split(' ')[0];

  let firstValue = data[0];
  if (!firstValue || firstValue[0] !== from) {
    data.unshift([from, null]);
  }

  let lastValue = data[data.length - 1];

  if (!lastValue || lastValue[0] !== to) {
    data.push([to, null]);
  }

  // start process
  let result = [];

  let start = -1;
  const stop = data.length - 1;

  while (++start <= stop) {
    const current = data[start];
    if (start === 0) {
      result.push(current);
      continue;
    }
    const previous = result[result.length - 1];
    insertDataPlusMissingData(result, previous, current);
  }

  return result;
};
