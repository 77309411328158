import React, { FunctionComponent, useEffect, useRef } from 'react';
import { useState } from 'react';
import { Chart, ChartData } from '../../Chart';
import { MetricsChartConfig } from '../../../constants';
import { ChartContainer, LoadState } from '../Helpers';
import { MetricsProps } from '../Metrics';
import { getOrgStressScoreCount } from '../../../lib/api';

export interface OrgStressScoreCountProps
  extends Omit<MetricsProps, 'type' | 'userId'> {}

export const OrgStressScoreCount: FunctionComponent<OrgStressScoreCountProps> = ({
  orgId,
  startDate,
  endDate,
  tags,
  tooltip,
  entity,
}) => {
  const chartOptions = MetricsChartConfig.stress_score_count({ entity });

  const dataRef = useRef<ChartData>({
    labels: [],
    series: [],
  });

  const [metricsLoadState, setMetricsLoadState] = useState<LoadState>(
    'loading'
  );

  useEffect(() => {
    if (metricsLoadState !== 'loading') {
      setMetricsLoadState('loading');
    }

    getOrgStressScoreCount({ orgId, startDate, endDate, tags }).then(
      (result) => {
        dataRef.current = {
          labels: result.sick.labels,
          series: [
            result.sick.series,
            result.rest.series,
            result.easy.series,
            result.good.series,
            result.great.series,
          ],
        };
        setMetricsLoadState('loaded');
      }
    );
  }, [orgId, startDate, endDate, tags]);

  return (
    <ChartContainer
      loadState={metricsLoadState}
      title={chartOptions.title}
      legends={chartOptions.legends.slice(0, dataRef.current.series.length)}
      tooltip={tooltip}
    >
      <Chart {...dataRef.current} chartOptions={chartOptions} />
    </ChartContainer>
  );
};
