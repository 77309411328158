import { createDefaultTransport, createTransport } from './helpers';

export const login = (email: string, password: string) => {
  return createTransport()
    .post(`/users/login`, {
      user_login: {
        email,
        password,
      },
    })
    .then((response) => response.data);
};

export const logout = () => {
  return createDefaultTransport().delete(`/users/sign_out`);
};

export * from './helpers';
export * from './getMetrics';
export * from './getSymptomMetrics';
export * from './getOrgUsers';
export * from './getOrgSymptoms';
export * from './getUserSymptoms';
export * from './getOrgStressScoreCount';
export * from './inviteUser';
export * from './referUser';
export * from './updateUserTags';
export * from './getOrgTags';
export * from './signupOrg';
