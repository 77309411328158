import { createDefaultTransport } from '../helpers';

export const referUser = (orgId: string, email: string) => {
  const transport = createDefaultTransport();

  return transport
    .post(`/dapi/orgs/${orgId}/send-referral-code`, { email })
    .then((response) => {
      console.log(response);
    });
};
