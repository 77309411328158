import { createDefaultTransport } from '../helpers';

export const updateUserTags = (
  orgId: string,
  userId: string,
  tags: string | string[]
) => {
  const transport = createDefaultTransport();

  return transport
    .put(`/dapi/orgs/${orgId}/users/${userId}/tags`, {
      tags: typeof tags === 'string' ? [tags] : tags,
    })
    .then((response) => {
      console.log(response);
    });
};
