import React, { ComponentProps, FunctionComponent } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { routePaths } from '../../constants';
import { useAuth } from '../../Providers';

export const NonPrivateRoute: FunctionComponent<
  ComponentProps<typeof Route>
> = ({ children, ...rest }) => {
  const { isLoggedIn } = useAuth();
  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (!isLoggedIn) {
          return children;
        } else {
          return (
            <Redirect
              to={{
                pathname: routePaths.home.path,
                state: { from: location },
              }}
            />
          );
        }
      }}
    />
  );
};
