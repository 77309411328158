import { Box, Typography } from '@material-ui/core';
import React, { FunctionComponent } from 'react';

export const RouteHeader: FunctionComponent = ({ children }) => {
  return (
    <Typography variant={'subtitle1'} component={'div'}>
      <Box mb={7} fontWeight={400} component={'h1'} mt={0}>
        {children}
      </Box>
    </Typography>
  );
};
