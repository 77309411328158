import { createDefaultTransport } from '../helpers';

export interface OrgUser {
  id: string;
  email: string;
  name: string;
  tags: string[];
}

export const getOrgUsers = (orgId: string) => {
  let endPoint = `/dapi/orgs/${orgId}/users`;

  return createDefaultTransport()
    .get(`${endPoint}`)
    .then((response) => {
      return response.data.data as OrgUser[];
    });
};
