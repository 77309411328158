import Axios from 'axios';
// import { createLoginRedirectHandler, storeLogginToken } from '../../utils';

const apiBaseUrl =
  process.env.REACT_APP_API_URL ||
  'https://missionready-staging.herokuapp.com/';

export const createTransport = (headers = {}) => {
  const axios = Axios.create({
    baseURL: apiBaseUrl,
    headers,
  });

  // axios.interceptors.response.use(
  //   function (response) {
  //     return response;
  //   },

  //   function (error) {
  //     if (error.response.status === 401) {
  //       storeLogginToken('');
  //       createLoginRedirectHandler(window.location.pathname)();
  //     }
  //     return Promise.reject(error);
  //   }
  // );
  return axios;
};
