export interface ChartistPluginAddExtraClassNamesOptions {
  addExtraClassNames?: boolean;
  pointClassNamesGenerator?: (
    seriesIndex: number,
    index: number,
    series: number[]
  ) => string | undefined | null | void;
  lineClassNamesGenerator?: (
    seriesIndex: number,
    index: number,
    series: number[]
  ) => string | undefined | null | void;
}

export const chartistPluginAddExtraClassNames = (
  Chartist,
  options: ChartistPluginAddExtraClassNamesOptions
) => {
  const createPlugin = (options: ChartistPluginAddExtraClassNamesOptions) => {
    return (chart) => {
      var defaultOptions: ChartistPluginAddExtraClassNamesOptions = {
        addExtraClassNames: true,
      };
      options = Chartist.extend({}, defaultOptions, options);

      if (chart instanceof Chartist.Line) {
        // data points have to be removed
        if (options.addExtraClassNames) {
          chart.on('draw', function (data) {
            if (data.type == 'point' && options.pointClassNamesGenerator) {
              const result = options.pointClassNamesGenerator(
                data.seriesIndex,
                data.index,
                data.series
              );
              if (typeof result === 'string' && result) {
                data.element.addClass(result);
              }
            }

            if (data.type == 'line' && options.lineClassNamesGenerator) {
              const result = options.lineClassNamesGenerator(
                data.seriesIndex,
                data.index,
                data.series
              );
              if (typeof result === 'string' && result) {
                data.element.addClass(result);
              }
            }
          });
        }
      }
    };
  };

  Chartist.plugins = Chartist.plugins || {};
  Chartist.plugins.addExtraClassNames = createPlugin(options);

  return Chartist.plugins.addExtraClassNames;
};
