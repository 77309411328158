//nothing here
export const parseQueryString = (
  qs: string,
  delimiter: string = '&'
): Map<string, string> => {
  if (qs.startsWith('?')) {
    qs = qs.substring(1);
  }
  return new Map(
    qs.split(delimiter).map((item) => {
      const [key, ...value] = item.split('=').map((part) => {
        try {
          return decodeURIComponent(part.trim());
        } catch (ex) {
          return part.trim();
        }
      });
      return [key, value.join('=')];
    })
  );
};

export const concatQueryString = (queryString: ReturnType<typeof parseQueryString>) => {
  const queries: string[] = [];
  queryString.forEach((value, key) => {
    if (key) {
      queries.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
    }
  })
  return queries.join('&');
}