import { useState, useRef, useEffect } from 'react';

export type ForceUpdateCallback = () => void;

export const useForceUpdate = () => {
  const stateRef = useRef(1);
  const [, setState] = useState(stateRef.current);

  const callbackRef = useRef<ForceUpdateCallback>(null);
  const promiseResolveRef = useRef<(value: boolean) => void>(null);

  const forceUpdateRef = useRef(
    (shouldExecuteOrCallback: boolean | (() => void) = true) => {
      return new Promise<boolean>((resolve) => {
        promiseResolveRef.current = resolve;

        stateRef.current = 0 - stateRef.current;
        if (shouldExecuteOrCallback) {
          if (typeof shouldExecuteOrCallback !== 'boolean') {
            callbackRef.current = shouldExecuteOrCallback;
          }
          setState(stateRef.current);
        }
      });
    }
  );

  useEffect(() => {
    if (callbackRef.current) {
      const callback = callbackRef.current;
      callbackRef.current = null;
      callback();
    }

    if (promiseResolveRef.current) {
      const callback = promiseResolveRef.current;
      promiseResolveRef.current = null;
      callback(true);
    }
  });

  return forceUpdateRef.current;
};
