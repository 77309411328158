import React, { FunctionComponent, useEffect, useRef } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { Button, Box, BoxProps } from '@material-ui/core';
import { useForceUpdate } from '../../hooks';
import { useHistory, useLocation } from 'react-router-dom';
import { concatQueryString, formatDate, parseQueryString } from '../../lib';

export interface DateSelectorProps extends BoxProps {
  initialStartDate: Date;
  initialEndDate: Date;

  onApply?: (startDate: Date, endDate: Date) => void;
}

// we should have another function the generates the initial start and end dates,
// you have to generate it and supply it
export const DateSelector: FunctionComponent<DateSelectorProps> = ({
  initialEndDate,
  initialStartDate,
  ...props
}) => {
  // we rely on the fact that the initialEndDate and InitialStartDate changes when we push to the ui,
  // to keep the date widget in sync, we need to store our state in refs, and watch for changes.

  const startDateRef = useRef(initialStartDate);
  const endDateRef = useRef(initialEndDate);

  const forceUpdate = useForceUpdate();

  const hasChangesRef = useRef(false);

  const location = useLocation();
  const history = useHistory();

  const handleStartDateChange = (value) => {
    hasChangesRef.current = true;
    startDateRef.current = value;
    forceUpdate();
  };

  const handleEndDateChange = (value) => {
    hasChangesRef.current = true;
    endDateRef.current = value;
    forceUpdate();
  };

  const onApply = (startDate: Date, endDate: Date) => {
    const queries = parseQueryString(location.search);
    queries.set('from', formatDate(startDate));
    queries.set('to', formatDate(endDate));

    // by pushing to the history, one or both of the initial start and end dates changes
    history.push(`${location.pathname}?${concatQueryString(queries)}`);
  };

  // called to commit changes
  const commitChanges = () => {
    if (hasChangesRef.current) {
      hasChangesRef.current = false;
      forceUpdate();
      onApply?.(startDateRef.current, endDateRef.current);
    }
  };

  // here we watch for changes in the initial start and end dates, and then force update the widget
  useEffect(() => {
    startDateRef.current = initialStartDate;
    endDateRef.current = initialEndDate;

    forceUpdate();
  }, [initialStartDate, initialEndDate]);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Box {...props}>
        <Box display={'flex'}>
          <KeyboardDatePicker
            disableToolbar
            variant='inline'
            format='MM/dd/yyyy'
            margin='normal'
            id='start-date-picker'
            label='Start Date'
            value={startDateRef.current}
            onChange={handleStartDateChange}
            KeyboardButtonProps={{
              'aria-label': 'change start date',
            }}
          />
          <KeyboardDatePicker
            variant={'inline'}
            margin='normal'
            id='end-date-picker'
            label='End Date'
            format='MM/dd/yyyy'
            value={endDateRef.current}
            onChange={handleEndDateChange}
            KeyboardButtonProps={{
              'aria-label': 'change end date',
            }}
          />
        </Box>

        <Box display={'flex'} alignContent={'flex-end'}>
          <Button
            color={'secondary'}
            variant={'contained'}
            size={'small'}
            disabled={!hasChangesRef.current}
            onClick={commitChanges}>
            Apply
          </Button>
        </Box>
      </Box>
    </MuiPickersUtilsProvider>
  );
};
