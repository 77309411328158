import React, { FunctionComponent, useEffect, useRef } from 'react';
import { useState } from 'react';
import { Chart, ChartData } from '../../Chart';
import { MetricEntity, MetricsChartConfig } from '../../../constants';
import { getMetrics, GetMetricsOptions } from '../../../lib/api/getMetrics';
import {
  ChartChartContainerProps,
  ChartContainer,
  LoadState,
} from '../Helpers';

export interface MetricsProps extends GetMetricsOptions {
  tooltip?: ChartChartContainerProps['tooltip'];
  entity: MetricEntity;
}

export const Metrics: FunctionComponent<MetricsProps> = ({
  entity,
  metricType,
  orgId,
  userId,
  startDate,
  endDate,
  tags,
  tooltip,
}) => {
  const chartOptions = MetricsChartConfig[metricType]({ entity });

  const dataRef = useRef<ChartData>({
    labels: [],
    series: [],
  });

  const [metricsLoadState, setMetricsLoadState] = useState<LoadState>(
    'loading'
  );

  useEffect(() => {
    if (metricsLoadState !== 'loading') {
      setMetricsLoadState('loading');
    }

    const promises: Array<ReturnType<typeof getMetrics>> = [
      getMetrics({
        metricType,
        orgId,
        startDate,
        endDate,
        tags,
      }),
      userId
        ? getMetrics({
            metricType,
            orgId,
            userId,
            startDate,
            endDate,
            tags,
          })
        : null,
    ].filter((current) => current !== null);

    Promise.all(promises)
      .then((result) => {
        const orgData = result[0];

        dataRef.current = {
          labels: orgData.labels,
          series: result.map((current) => current.series),
        };

        setMetricsLoadState('loaded');
      })
      .catch((ex) => {
        setMetricsLoadState('error');
      });
  }, [orgId, userId, startDate, endDate, tags]);

  return (
    <ChartContainer
      loadState={metricsLoadState}
      title={chartOptions.title}
      legends={chartOptions.legends.slice(0, dataRef.current.series.length)}
      tooltip={tooltip}
    >
      <Chart {...dataRef.current} chartOptions={chartOptions} />
    </ChartContainer>
  );
};
