import { useRef } from 'react';

export const useProviderValueWithDispatch = <ValueType, DispatchType>(
  value: ValueType,
  dispatch: DispatchType,
) => {
  const valueRef = useRef({ value, dispatch });

  if (valueRef.current.value !== value) {
    valueRef.current = { value, dispatch };
  }

  return valueRef.current;
};
