import React, { FunctionComponent, useEffect } from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormLabel,
  makeStyles,
  Radio,
  RadioGroup,
  TextField,
} from '@material-ui/core';
import { RouteHeader } from '../../Components/RouteHeader';

import {
  Formik,
  ErrorMessage as FormikErrorMessage,
  FormikValues,
  FormikHelpers,
} from 'formik';
import * as Yup from 'yup';
import { useSearchParams } from '../../hooks';
import { signupOrg } from '../../lib';
import { useAuth, useAuthDispatch } from '../../Providers';
import { Redirect } from 'react-router';
import { routePaths } from '../../constants';

const useErrorStyles = makeStyles((theme) => {
  return {
    error: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      fontSize: 12,
      color: theme.palette.warning.main,
    },
  };
});

const ErrorMessage: FunctionComponent<{ name: string }> = ({
  name,
  ...props
}) => {
  const Component = FormikErrorMessage as any;
  const classNames = useErrorStyles();
  return (
    <Component
      // @ts-ignore
      // @ts-ignore
      fontSize={14}
      // @ts-ignore
      name={name}
      // @ts-ignore
      component={Box}
      // @ts-ignore
      {...props}
      className={classNames.error}
    />
  );
};

const useStyles = makeStyles((theme) => {
  return {
    formLabel: {
      display: 'block',
      marginBottom: theme.spacing(2),
    },

    description: {
      color: '#303030',
      fontWeight: 300,
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      fontSize: 13,
    },

    bigDescription: {
      color: '#303030',
      fontWeight: 300,
      fontSize: 18,
    },

    radioLabel: {
      '& .MuiFormControlLabel-label': {
        color: '#303030',
        fontWeight: 300,
        fontSize: 13,
      },
    },
  };
});

const formNames = {
  org_name: 'org_name',
  first_name: 'first_name',
  last_name: 'last_name',
  email: 'email',

  password: 'password',

  password_confirmation: 'password_confirmation',

  consent: 'consent',

  signedup_with_referral_code: 'signedup_with_referral_code',

  country: 'country',

  dashboard_type: 'dashboard_type',

  solution_size: 'solution_size',

  signup_questions: 'signup_questions',
};

export const SignupRoute = () => {
  const classNames = useStyles();
  const searchParams = useSearchParams();
  const authDispatcher = useAuthDispatch();
  const auth = useAuth();

  if (auth.isLoggedIn) {
    return <Redirect to={routePaths.help.path} />;
  }

  const handleSubmit = (
    values: FormikValues,
    { setSubmitting, resetForm, setFieldError }: FormikHelpers<any>
  ) => {
    const { password, password_confirmation } = values;
    if (password !== password_confirmation) {
      setFieldError(formNames.password_confirmation, 'Passwords did not match');
      setSubmitting(false);
      return;
    }

    setSubmitting(true);
    signupOrg(values)
      .then((result) => {
        authDispatcher({ type: 'login', token: result.token });
      })
      .catch((ex) => {
        const data = ex.response.data;
        if (data) {
          for (const key of Object.keys(data)) {
            setFieldError(key, data[key]);
          }
        } else {
          alert('error occurred while submitting data, please try again');
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <Box maxWidth={900}>
      <RouteHeader>Healthzilla Wellness - Register</RouteHeader>

      <Formik
        initialValues={{
          [formNames.org_name]: '',
          [formNames.first_name]: '',
          [formNames.last_name]: '',
          [formNames.email]: '',
          [formNames.password]: '',
          [formNames.password_confirmation]: '',

          [formNames.consent]: false,

          [formNames.signedup_with_referral_code]: searchParams.referral_code,

          [formNames.country]: '',

          [formNames.dashboard_type]: '',

          [formNames.solution_size]: '',

          [formNames.signup_questions]: '',
        }}
        validationSchema={Yup.object({
          // org name
          [formNames.org_name]: Yup.string().required(
            'Organisation name is required'
          ),

          // name validation
          [formNames.first_name]: Yup.string().required(
            'First name is required'
          ),
          [formNames.last_name]: Yup.string().required('Last name is required'),

          // email validation
          [formNames.email]: Yup.string()
            .email()
            .required('please enter email address'),

          // password validation
          [formNames.password]: Yup.string()
            .min(6, 'password must be at least 6 characters')
            .required(),

          [formNames.password_confirmation]: Yup.string()
            .min(6, 'password must be at least 6 characters')
            .required(),

          // country validation
          [formNames.country]: Yup.string().required(
            'Please enter your country name'
          ),

          // dashboard type
          [formNames.dashboard_type]: Yup.string()
            .required()
            .oneOf(
              ['ALL', 'ONLY_AGGREGATE'],
              "Specify your organisation's users type"
            ),

          // solution size
          [formNames.solution_size]: Yup.string()
            .required()
            .oneOf(['small', 'medium', 'custom'], 'Specify a solution plan'),

          // accept terms
          [formNames.consent]: Yup.bool().oneOf(
            [true],
            'Accept Terms & Conditions is required'
          ),

          // email validation
          [formNames.signup_questions]: Yup.string(),
        })}
        onSubmit={handleSubmit}
      >
        {(formik) => {
          return (
            <form method={'post'} onSubmit={formik.handleSubmit}>
              {/* organisation name */}
              <Box>
                <FormLabel
                  className={classNames.formLabel}
                  htmlFor={formNames.org_name}
                >
                  Organisation Name *
                </FormLabel>
                <TextField
                  variant={'outlined'}
                  size={'small'}
                  placeholder={'organization name'}
                  fullWidth
                  id={formNames.org_name}
                  name={formNames.org_name}
                  value={formik.values.org_name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />

                <ErrorMessage name={formNames.org_name} />
              </Box>
              {/* organisation admin name */}
              <Box display={'flex'} mt={8}>
                {/* first name */}
                <Box flex={1} mr={[0, 0, 4]} flexWrap={'wrap'}>
                  <FormLabel
                    className={classNames.formLabel}
                    htmlFor={formNames.first_name}
                  >
                    First Name *
                  </FormLabel>
                  <TextField
                    variant={'outlined'}
                    size={'small'}
                    placeholder={'first name'}
                    fullWidth
                    id={formNames.first_name}
                    name={formNames.first_name}
                    value={formik.values.first_name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />

                  <ErrorMessage name={formNames.first_name} />
                </Box>

                {/* last name */}
                <Box flex={1} ml={[0, 0, 4]}>
                  <FormLabel
                    className={classNames.formLabel}
                    htmlFor={formNames.last_name}
                  >
                    Last Name *
                  </FormLabel>
                  <TextField
                    variant={'outlined'}
                    size={'small'}
                    placeholder={'last name'}
                    fullWidth
                    id={formNames.last_name}
                    name={formNames.last_name}
                    value={formik.values.last_name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />

                  <ErrorMessage name={formNames.last_name} />
                </Box>
              </Box>
              {/* email address */}
              <Box mt={8}>
                <FormLabel
                  className={classNames.formLabel}
                  htmlFor={formNames.email}
                >
                  Email Address *
                </FormLabel>
                <TextField
                  type={'email'}
                  variant={'outlined'}
                  size={'small'}
                  placeholder={'email address'}
                  fullWidth
                  id={formNames.email}
                  name={formNames.email}
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />

                <ErrorMessage name={formNames.email} />
              </Box>

              {/* password */}
              <Box mt={8}>
                <FormLabel
                  className={classNames.formLabel}
                  htmlFor={formNames.password}
                >
                  Password *
                </FormLabel>
                <TextField
                  type={'password'}
                  variant={'outlined'}
                  size={'small'}
                  placeholder={'enter password'}
                  fullWidth
                  id={formNames.password}
                  name={formNames.password}
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />

                <ErrorMessage name={formNames.password} />
              </Box>

              {/* confirm password */}
              <Box mt={8}>
                <FormLabel
                  className={classNames.formLabel}
                  htmlFor={formNames.password_confirmation}
                >
                  Confirm Password *
                </FormLabel>
                <TextField
                  type={'password'}
                  variant={'outlined'}
                  size={'small'}
                  placeholder={'confirm password'}
                  fullWidth
                  id={formNames.password_confirmation}
                  name={formNames.password_confirmation}
                  value={formik.values.password_confirmation}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />

                <ErrorMessage name={formNames.password_confirmation} />
              </Box>

              {/* country */}
              <Box mt={8}>
                <FormLabel
                  className={classNames.formLabel}
                  htmlFor={formNames.country}
                >
                  Where are you based? *
                </FormLabel>
                <Box className={classNames.description}>Country</Box>
                <TextField
                  variant={'outlined'}
                  size={'small'}
                  placeholder={'country'}
                  fullWidth
                  id={formNames.country}
                  name={formNames.country}
                  value={formik.values.country}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />

                <ErrorMessage name={formNames.country} />
              </Box>

              {/* organisation user types */}
              <Box mt={8}>
                <FormLabel
                  className={classNames.formLabel}
                  htmlFor={formNames.dashboard_type}
                >
                  I want to use Healthzilla with: *
                </FormLabel>
                <Box fontSize={12} className={classNames.description}>
                  Choose 'Clients' if you need a view to individual end users'
                  data (e.g. you are a personal trainer), and 'Employees' if you
                  need an aggregate view to end users' data only (e.g. you are
                  an HR person in an organization).
                </Box>
                <Box fontSize={12} className={classNames.description}>
                  NB: Access to requested data is subject to end users'
                  approval. After filling this form, Healthzilla will provide
                  you a link to a dedicated consent form with which end users'
                  approval will be requested and documented.
                </Box>

                <Box>
                  <RadioGroup
                    aria-label={formNames.dashboard_type}
                    name={formNames.dashboard_type}
                    onChange={formik.handleChange}
                    value={formik.values.dashboard_type}
                  >
                    <FormControlLabel
                      value="ALL"
                      control={<Radio size={'small'} />}
                      label="Clients"
                      className={classNames.radioLabel}
                    />
                    <FormControlLabel
                      value="ONLY_AGGREGATE"
                      control={<Radio size={'small'} />}
                      label="Employee"
                      className={classNames.radioLabel}
                    />
                  </RadioGroup>

                  <ErrorMessage name={formNames.dashboard_type} />
                </Box>
              </Box>

              {/* solution size */}
              <Box mt={8}>
                <FormLabel
                  className={classNames.formLabel}
                  htmlFor={formNames.solution_size}
                >
                  I choose the following solution *
                </FormLabel>
                <Box fontSize={12} className={classNames.description}>
                  Please choose which solution you want to get started with. No
                  worries, you can always change it later if your situation
                  changes!
                </Box>

                <Box>
                  <RadioGroup
                    aria-label={formNames.solution_size}
                    name={formNames.solution_size}
                    onChange={formik.handleChange}
                  >
                    <FormControlLabel
                      value="small"
                      control={<Radio size={'small'} />}
                      label="Small (up to 15 clients/employees)"
                      className={classNames.radioLabel}
                    />
                    <FormControlLabel
                      value="medium"
                      control={<Radio size={'small'} />}
                      label="Medium (16-30 clients/employees)"
                      className={classNames.radioLabel}
                    />
                    <FormControlLabel
                      value="custom"
                      control={<Radio size={'small'} />}
                      label="I need something else, please contact me!"
                      className={classNames.radioLabel}
                    />
                  </RadioGroup>

                  <ErrorMessage name={formNames.solution_size} />
                </Box>
              </Box>

              {/* referral code */}
              <Box mt={8}>
                <FormLabel
                  className={classNames.formLabel}
                  htmlFor={'signedup_with_referral_code'}
                >
                  Referral code
                </FormLabel>
                <Box fontSize={12} className={classNames.description}>
                  In case you received a code from an existing user, fill it in
                  below and both will receive a free month.
                </Box>
                <Box>
                  <TextField
                    variant={'outlined'}
                    size={'small'}
                    placeholder={'Referral code'}
                    fullWidth
                    id={formNames.signedup_with_referral_code}
                    name={formNames.signedup_with_referral_code}
                    value={formik.values.signedup_with_referral_code}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </Box>
              </Box>

              {/* questions */}
              <Box mt={8}>
                <FormLabel
                  className={classNames.formLabel}
                  htmlFor={'signup_questions'}
                >
                  Please type here any questions you might have at this point
                </FormLabel>
                <Box>
                  <TextField
                    multiline
                    variant={'outlined'}
                    fullWidth
                    value={formik.values.signup_questions}
                    id={formNames.signup_questions}
                    name={formNames.signup_questions}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />

                  <ErrorMessage name={formNames.signup_questions} />
                </Box>
              </Box>

              {/* consent box */}
              <Box mt={8}>
                <FormLabel
                  className={classNames.formLabel}
                  htmlFor={formNames.consent}
                >
                  I give consent to collect my data in order to process my
                  request *
                </FormLabel>

                <FormControlLabel
                  control={
                    <Checkbox
                      size={'small'}
                      name={formNames.consent}
                      id={formNames.consent}
                    />
                  }
                  label="Yes"
                  className={classNames.radioLabel}
                  onChange={formik.handleChange}
                />

                <ErrorMessage name={formNames.consent} />
              </Box>

              {/* submit button */}
              <Box mt={8}>
                <Button type={'submit'} variant={'outlined'}>
                  Send
                </Button>
              </Box>

              {/* notes */}
              <Box mt={8} component={'p'} className={classNames.bigDescription}>
                Your support &amp; feedback means a ton to us. By submitting
                this form, you are accepting our terms of usage for the
                dashboard. We’ll do our very best to uphold our part!
              </Box>

              <Box mt={8} component={'p'} className={classNames.bigDescription}>
                <strong>NOTE:</strong> You will NOT be charged anything at all,
                unless you wish to keep using the dashboard after the first
                month! If you choose to keep using it, we will send you an
                invoice at the end of each month as per the pricing scheme of
                the service you have chosen. If you decide not to pay, we will
                just disable your account after another 30 days, no harm no
                foul!
              </Box>
            </form>
          );
        }}
      </Formik>
    </Box>
  );
};
