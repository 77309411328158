import { LinkProps, makeStyles } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import { useIsActivePath } from '../../hooks';

const useStyles = makeStyles((theme) => ({
  link: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    color: 'inherit',
    textDecoration: 'none',
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    borderBottom: (props: any) =>
      `2px solid ${props.isActive ? `white` : `transparent`}`,
  },
}));

export interface NavLinkProps extends LinkProps {
  to: string;
}

export const NavLink: FunctionComponent<NavLinkProps> = ({
  to,
  children,
  ...props
}) => {
  const isActivePath = useIsActivePath(to);
  const classes = useStyles({ isActive: isActivePath });

  if (to.startsWith('/')) {
    return (
      <Link to={to} className={classes.link} {...props}>
        {children}
      </Link>
    );
  }
  return (
    <a href={to} className={classes.link} {...props}>
      {children}
    </a>
  );
};
