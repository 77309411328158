import { Box, CircularProgress } from '@material-ui/core';
import React, { useEffect, useRef } from 'react';
import { RouteHeader } from '../../../Components/RouteHeader';
import { getOrgUsers, OrgUser, updateUserTags } from '../../../lib';
import { useAuth } from '../../../Providers';
import { RouteComponent } from '../../../typings';
import uniq from 'lodash/uniq';

import capitalize from 'lodash/capitalize';
import { useForceUpdate, useOrgTags } from '../../../hooks';
import { TaggedUser } from './Helpers/TaggedUser';

export interface OrgUsersRouteProps {}

export const OrgUsersRoute: RouteComponent<OrgUsersRouteProps> = ({
  location,
}) => {
  const stateRef = useRef<{
    users: OrgUser[];
    loadState: 'loading' | 'loaded' | 'error';
  }>({
    users: [],
    loadState: 'loading',
  });

  const { user: org } = useAuth();

  const { orgTags, setOrgTags } = useOrgTags(org.org_id);

  const forceUpdate = useForceUpdate();

  /**
   * load users
   */
  const loadUsers = () => {
    if (stateRef.current.loadState !== 'loading') {
      stateRef.current.loadState = 'loading';
      forceUpdate();
    }

    getOrgUsers(org.org_id)
      .then((users) => {
        stateRef.current.users = users;
        stateRef.current.loadState = 'loaded';
      })
      .catch((ex) => {
        stateRef.current.loadState = 'error';
      })
      .finally(() => forceUpdate());
  };

  const saveUserTags = (user: OrgUser, tags: string[]) => {
    return updateUserTags(org.org_id, user.id, tags).then(() => {
      stateRef.current.users = stateRef.current.users.map((current) => {
        if (current.id === user.id) {
          return {
            ...current,
            tags,
          };
        }
        return current;
      });

      return setOrgTags(uniq(orgTags.concat(tags)));
    });
  };

  useEffect(() => {
    loadUsers();
  }, []);

  const { loadState, users } = stateRef.current;

  return (
    <Box maxWidth={700}>
      <RouteHeader>{capitalize(org.org_name)} Users</RouteHeader>

      {loadState === 'loading' && (
        <Box py={10} px={10}>
          <CircularProgress />
        </Box>
      )}

      {loadState === 'loaded' && users.length === 0 && (
        <Box py={10}> No users found</Box>
      )}

      {loadState === 'loaded' &&
        users.length > 0 &&
        users.map((user, index) => {
          return (
            <TaggedUser
              orgTags={orgTags}
              user={user}
              saveUserTags={saveUserTags}
              key={user.id}
            />
          );
        })}
    </Box>
  );
};
