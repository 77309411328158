import { Box, TextField, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { HabitLevelsMetrics, Metrics } from '../../../Components';
import { DateSelector } from '../../../Components/DateSelector';
import { OrgStressScoreCount } from '../../../Components/Metrics/OrgStressScoreCount';
import { SymptomMetrics } from '../../../Components/Metrics/SymptomMetrics';
import { MetricType } from '../../../constants';
import { useOrgTags } from '../../../hooks';
import { useDates } from '../../../hooks/useDates';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { RouteHeader } from '../../../Components/RouteHeader';

export interface OrgMetricsRouteParams {
  orgId: string;
  userId: string;
  metrics: MetricType | 'all';
}

export const OrgMetricsRoute = () => {
  const { orgId, metrics } = useParams() as OrgMetricsRouteParams;

  const checkMetric = (value: OrgMetricsRouteParams['metrics']) => {
    return metrics === value || metrics === 'all';
  };

  const { startDate, endDate } = useDates();

  const { orgTags } = useOrgTags(orgId);

  const [selectedTags, setSelectedTags] = useState<string[]>([]);

  // const tagSelectionRef = useRef({
  //   selected: [],
  //   hasChanges: false,
  // });

  // const onTagSelectionDone = () => {
  //   if (tagSelectionRef.current.hasChanges) {
  //     tagSelectionRef.current.hasChanges = false;
  //     setSelectedTags(tagSelectionRef.current.selected);
  //   }
  // };

  // generate dates if not given in the query parameters.
  return (
    <Box>
      <RouteHeader>Org Charts</RouteHeader>
      <Box mb={10} display={'flex'} justifyContent={'space-between'}>
        <Box mt={5}>
          <Autocomplete
            options={orgTags}
            autoComplete
            multiple
            onChange={(event, newValue) => {
              setSelectedTags([...newValue]);
            }}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  variant={'outlined'}
                  style={{ minWidth: 200, maxWidth: 400 }}
                  placeholder={'Filter by tags'}
                  // inputRef={inputRef}
                  size={'medium'}
                  // className={classNames.tagTextField}
                />
              );
            }}
            // onBlur={onTagSelectionDone}
          />
        </Box>

        <Box ml={3}>
          <DateSelector initialStartDate={startDate} initialEndDate={endDate} />
        </Box>
      </Box>

      {checkMetric('stress_score_count') && (
        <OrgStressScoreCount
          entity={'org'}
          orgId={orgId}
          startDate={startDate}
          endDate={endDate}
          tags={selectedTags}
          tooltip={{
            descriptions: [
              'Number of Users each day with each Stress Score: Great, Good, Easy, Rest, or Sick',
              'The daily Stress Score is calculated based on recent trends in biometric and behavioral data for each User',
            ],
            readMoreLink: {
              link:
                'https://www.healthzilla.ai/science/2019/11/30/stress-score',
            },
          }}
        />
      )}

      {checkMetric('hrv') && (
        <Metrics
          entity={'org'}
          metricType={'hrv'}
          orgId={orgId}
          startDate={startDate}
          endDate={endDate}
          tags={selectedTags}
          tooltip={{
            descriptions: [
              'Heart Rate Variability ("HRV") is the leading indicator of stress for your body.',
              'HRV is measured in milliseconds and indicates the variability in delay between your individual heartbeats.',
            ],

            readMoreLink: {
              link:
                'https://www.healthzilla.ai/science/2019/10/17/heart-rate-variability',
              intro:
                'This data comes from the fingertip Stress Scan. You can read in-depth about HRV',
              linkText: 'here',
            },
          }}
        />
      )}

      {checkMetric('rhr') && (
        <Metrics
          entity={'org'}
          metricType={'rhr'}
          orgId={orgId}
          startDate={startDate}
          endDate={endDate}
          tags={selectedTags}
          tooltip={{
            descriptions: [
              'Resting Heart Rate (“RHR”) is a general indicator of your recovery and overall health status',
              'This data is measured in beats-per-minute and comes from the fingertip Stress Scan.',
            ],
          }}
        />
      )}

      {checkMetric('symptoms') && (
        <SymptomMetrics
          entity={'org'}
          orgId={orgId}
          startDate={startDate}
          endDate={endDate}
          tags={selectedTags}
          tooltip={{
            descriptions: [
              'After each Stress Scan, Users can report symptoms of Stress or Sickness.',
            ],
          }}
        />
      )}

      {checkMetric('habit_levels') && (
        <HabitLevelsMetrics
          entity={'org'}
          orgId={orgId}
          startDate={startDate}
          endDate={endDate}
          tags={selectedTags}
          tooltip={{
            descriptions: [
              'The Level indicates how often a Habit is completed each week.',
              'Level 1 means once a week, Level 7 means every day.',
            ],
            readMoreLink: {
              link: 'https://www.healthzilla.ai/science/healthy-habits',
            },
          }}
        />
      )}
    </Box>
  );
};
