import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { EmailForm } from '../../../Components/Forms';
import { RouteHeader } from '../../../Components/RouteHeader';
import { inviteUser, referUser } from '../../../lib';
import { useAuth } from '../../../Providers';
import { RouteComponent } from '../../../typings';

import capitalize from 'lodash/capitalize';

export interface OrgSettingsRouteProps {}

export const OrgSettingsRoute: RouteComponent<OrgSettingsRouteProps> = ({
  location,
}) => {
  const { user } = useAuth();

  const sendInvite = (email: string) => {
    return inviteUser(user.org_id, email).then((result) => {
      return true;
    });
  };

  const sendReferralEmail = (email: string) => {
    return referUser(user.org_id, email).then((result) => {
      return true;
    });
  };

  return (
    <Box maxWidth={600}>
      <RouteHeader>
        {capitalize(user.org_name)} Organisation Settings
      </RouteHeader>

      {/* user invite */}
      <Box>
        <Typography variant={'subtitle1'} component={'div'}>
          <Box mb={6} component={'h3'} fontWeight={'bold'}>
            Invite User to organisation
          </Box>
        </Typography>

        <EmailForm
          formId={'invite-form'}
          buttonText={'Invite'}
          onSubmit={sendInvite}
        />
      </Box>

      {/* referral program */}
      <Box mt={11}>
        <Typography variant={'subtitle1'} component={'div'}>
          <Box mb={6} component={'h3'} fontWeight={'bold'}>
            Referral program
          </Box>
        </Typography>

        <Typography component={'div'}>
          <Box component={'p'}>
            Get a free month for your org and the invited org with our referral
            program. Tell your friends to use the referral code{' '}
            <Box component={'b'} fontWeight={'bold'}>
              {user.org_referral_code?.toUpperCase()}
            </Box>
          </Box>

          <Box component={'p'} mt={5} mb={7}>
            You can also send them an email with the code here
          </Box>
        </Typography>

        <EmailForm
          formId={'referral-form'}
          buttonText={'Send'}
          onSubmit={sendReferralEmail}
          emailPlaceholder={'foo@example.org'}
        />
      </Box>
    </Box>
  );
};
