import React, { FunctionComponent, useMemo } from 'react';
import { useProviderValueWithDispatch } from '../helpers';

import decodeJWT from 'jwt-decode';
import { getLogginToken, storeLogginToken } from '../../lib';

export interface AuthUser {
  user_id: string;
  email: string;
  org_id: string;
  org_referral_code: string;
  org_privacy_limit: string;
  org_name: string;
  dashboard_type: 'ONLY_AGGREGATE' | 'ALL';
  sub: string;
  scp: string;

  exp: number;
}

export type AuthProviderStateValue =
  | { isLoggedIn: false; user: null; token: string }
  | { isLoggedIn: true; user: AuthUser; token: string };

export type AuthChangeCallback = (props: AuthProviderStateValue) => void;

export type AuthProviderDispatchAction =
  | { type: 'login'; token: string; callback?: AuthChangeCallback }
  | { type: 'logout'; callback?: AuthChangeCallback };

export interface AuthProviderActionDispatcher {
  (action: AuthProviderDispatchAction): void;
}

export interface AuthProviderState {
  value: AuthProviderStateValue;
}

export interface AuthProviderStateWithDispatch extends AuthProviderState {
  dispatch: AuthProviderActionDispatcher;
}

export const AuthProviderContext = React.createContext<AuthProviderStateWithDispatch>(
  null as any
);

/**
 * state reducer
 */
const reducer = (
  state: AuthProviderState,
  action: AuthProviderDispatchAction
): AuthProviderState => {
  let newState: AuthProviderStateValue;

  switch (action.type) {
    case 'login':
      newState = {
        isLoggedIn: true,
        token: action.token,
        user: decodeJWT(action.token),
      };

      storeLogginToken(action.token);
      break;

    case 'logout':
      newState = {
        isLoggedIn: false,
        token: '',
        user: null,
      };
      storeLogginToken('');
      break;
  }

  action.callback?.(newState);

  return {
    ...state,
    value: newState,
  };
};

/**
 * used to access state
 */
export const useAuth = (): AuthProviderStateValue => {
  const context = React.useContext(AuthProviderContext);
  if (context === undefined) {
    if (process.env.NODE_ENV !== 'production') {
      throw new Error('useAuth hook must be called within an AuthProvider');
    }
  }
  return context.value;
};

export const useAuthWithDispatch = () => {
  const context = React.useContext(AuthProviderContext);
  if (context === undefined) {
    if (process.env.NODE_ENV !== 'production') {
      throw new Error(
        'useAuthWithDispatch hook must be called within an AuthProvider'
      );
    }
  }
  return {
    isLoggedIn: context.value.isLoggedIn,
    user: context.value.user,
    dispatch: context.dispatch,
  };
};

/**
 * used to access state
 */
export const useAuthDispatch = (): AuthProviderActionDispatcher => {
  const context = React.useContext(AuthProviderContext);
  if (context === undefined) {
    if (process.env.NODE_ENV !== 'production') {
      throw new Error(
        'useAuthDispatch hook must be called within an AuthProvider'
      );
    }
  }
  return context.dispatch;
};

/**
 * used to access state
 */
export const useIsLoggedIn = (): boolean => {
  return useAuth().isLoggedIn;
};

export const AuthProvider: FunctionComponent<{}> = ({ children }) => {
  let initialState: AuthProviderStateValue = useMemo(() => {
    const token = getLogginToken();
    let state: AuthProviderStateValue;
    if (token) {
      state = reducer({} as any, { type: 'login', token }).value;
    } else {
      state = reducer({} as any, { type: 'logout' }).value;
    }

    if (state && state.user) {
      const exp = state.user.exp * 1000;
      if (Date.now() >= exp) {
        state = reducer({} as any, { type: 'logout' }).value;
      }
    }

    return state;
  }, []);

  const [state, dispatch] = React.useReducer(reducer, { value: initialState });

  const providerValue = useProviderValueWithDispatch(state.value, dispatch);

  return (
    <AuthProviderContext.Provider value={providerValue}>
      {children}
    </AuthProviderContext.Provider>
  );
};
