import React from 'react';
import { BrowserRouter as ReactRouter, Switch, Route } from 'react-router-dom';
import { routePaths } from '../constants';
import { LoginRoute } from './LoginRoute';
import { HomeRoute } from './HomeRoute';
import { PrivateRoute } from './PrivateRoute';
import { Layout } from '../Layout';
import { OrgSettingsRoute, OrgUsersRoute, OrgMetricsRoute } from './OrgRoutes';
import { UserMetricsRoute } from './UserRoutes';
import { NonPrivateRoute } from './NonPrivateRoute';
import { SignupRoute } from './SignupRoute';
import { HelpRoute } from './HelpRoute';

export const Routes = () => {
  return (
    <ReactRouter>
      <Layout>
        <Switch>
          <Route path={routePaths.home.path} exact>
            <HomeRoute />
          </Route>

          <Route path={routePaths.signup.path} exact>
            <SignupRoute />
          </Route>

          <NonPrivateRoute path={routePaths.login.path} exact>
            <LoginRoute />
          </NonPrivateRoute>

          <PrivateRoute path={routePaths.help.path} exact>
            <HelpRoute />
          </PrivateRoute>

          <PrivateRoute path={routePaths.orgMetric.path} exact>
            <OrgMetricsRoute />
          </PrivateRoute>

          <PrivateRoute path={routePaths.userMetric.path} exact>
            <UserMetricsRoute />
          </PrivateRoute>

          <PrivateRoute path={routePaths.orgSettings.path} exact>
            <OrgSettingsRoute />
          </PrivateRoute>

          <PrivateRoute path={routePaths.orgUsers.path} exact>
            <OrgUsersRoute />
          </PrivateRoute>
        </Switch>
      </Layout>
    </ReactRouter>
  );
};
