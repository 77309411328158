import { Box, Button, Link, TextField, Typography } from '@material-ui/core';
import { AxiosError } from 'axios';
import React, { FunctionComponent, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { routePaths } from '../constants';
import { login } from '../lib';
import { useAuth, useAuthDispatch } from '../Providers';
import { RouteProps } from './HomeRoute';

export interface LoginRouteProps extends RouteProps {}

export interface LoginResult {
  token: string;
}

export interface Error {
  errors: { message: string }[];
}

type LoginState = 'initial' | 'authenticating' | 'error' | 'authenticated';

export const LoginRoute: FunctionComponent<LoginRouteProps> = ({
  location,
}) => {
  const auth = useAuth();
  const authDispatcher = useAuthDispatch();

  let { from } = location?.state || { from: { pathname: '/' } };

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loginState, setLoginState] = useState<LoginState>('initial');
  const [loginError, setLoginError] = useState<Error | undefined>();

  const doLogin = () => {
    if (loginState !== 'authenticating') {
      setLoginState('authenticating');
    }

    login(email, password)
      .then(({ token }: LoginResult) => {
        authDispatcher({ type: 'login', token });
        setLoginState('authenticated');
      })
      .catch((e: AxiosError) => {
        const error = (e?.response?.data as Error) || {
          errors: [{ message: 'Problem logging in' }],
        };
        setLoginError(error);
        setLoginState('error');
      });
  };

  if (auth.isLoggedIn || loginState === 'authenticated') {
    return <Redirect to={from} />;
  }

  return (
    <Box width={300}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          doLogin();
          return false;
        }}
      >
        <Box my={2}>
          <Typography component="h1" variant="h5">
            Login
          </Typography>
        </Box>
        {loginState === 'error' && (
          <Box color="warning" mb={1}>
            <Typography>
              {(loginError.errors || []).map((e) => e.message).join(', ')}
            </Typography>
          </Box>
        )}
        <Box mb={1}>
          <TextField
            type="email"
            placeholder="Email"
            name="username"
            variant="outlined"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            required
            autoFocus
            fullWidth
          />
        </Box>
        <Box mb={2}>
          <TextField
            type="password"
            placeholder="Password"
            name="password"
            variant="outlined"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
            required
            fullWidth
          />
        </Box>
        <Button type="submit" variant="contained" color="primary" fullWidth>
          Login
        </Button>
      </form>
      <Box mt={5}>
        <Typography>
          Not signed up? <Link href={routePaths.signup.path}>Goto Signup</Link>
        </Typography>
      </Box>
    </Box>
  );
};
