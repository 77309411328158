import { constructMetricsEndpoint, GetMetricsOptions } from '../getMetrics';
import { createDefaultTransport } from '../helpers';

export const getHabitLevels = (options: GetMetricsOptions) => {
  const transport = createDefaultTransport();

  let endPoint = constructMetricsEndpoint({
    ...options,
    metricType: 'habit_levels',
  });

  return transport.get(endPoint).then((response) => {
    const { data } = response.data;

    return {
      labels: data.map((current) => current[0]),
      series: data.map((current) => current[1]),
    };
  });
};
