import { useEffect, useState } from 'react';
import { IS_BROWSER } from '../../constants';

export interface useMediaQueryOptions {
  defaultValue?: boolean;
  watchChanges?: boolean;

  onChange?: (matches: boolean) => void;
}

export const useMediaQuery = (
  query: string,
  options?: useMediaQueryOptions
) => {
  query = (query.indexOf('@media') > -1
    ? query.split('@media')[1]
    : query
  ).trim();

  const { defaultValue = true, watchChanges = true, onChange } = options || {};

  const isSupported = IS_BROWSER && typeof window.matchMedia !== 'undefined';

  const [matches, setMatches] = useState(defaultValue);

  const updateResult = (matches: boolean) => {
    onChange?.(matches);
    setMatches(matches);
  };

  useEffect(() => {
    if (!isSupported || !query) {
      return;
    }

    const mediaQueryList = window.matchMedia(query);
    updateResult(mediaQueryList.matches);

    const listener = () => {
      updateResult(mediaQueryList.matches);
    };

    if (watchChanges) {
      mediaQueryList.addListener(listener);
      return () => mediaQueryList.removeListener(listener);
    }
  }, [query, watchChanges, onChange]);

  return matches;
};
