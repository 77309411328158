import { createDefaultTransport } from '../helpers';

export const signupOrg = (data) => {
  const transport = createDefaultTransport();

  return transport
    .post(`/dapi/orgs`, data, { responseType: 'json' })
    .then((response) => {
      return response.data;
    });
};
