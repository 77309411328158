import { MetricType } from '../../../constants';
import { formatDate } from '../../utils';
import { createDefaultTransport, interleaveData } from '../helpers';

export interface GetMetricsOptions {
  metricType?: MetricType;
  orgId: string;
  userId?: string;
  startDate: Date;
  endDate: Date;

  tags?: string[];
}

export const constructMetricsEndpoint = (options: GetMetricsOptions) => {
  const { metricType, orgId, userId, startDate, endDate, tags = [] } = options;
  let endPoint = `/dapi/metrics/${metricType.toUpperCase()}/orgs/${orgId}`;
  if (userId) {
    endPoint += `/users/${userId}`;
  }

  const params = [`from=${formatDate(startDate)}`, `to=${formatDate(endDate)}`];

  if (tags && tags.length) {
    params.push(`tags=${tags.join(',')}`);
  }

  return `${endPoint}?${params.join('&')}`;
};

export const getMetrics = (options: GetMetricsOptions) => {
  return createDefaultTransport()
    .get(constructMetricsEndpoint(options))
    .then((response) => {
      const data = interleaveData(
        response.data.data,
        response.data.from,
        response.data.to
      );
      return {
        labels: data.map((current) => current[0]),
        series: data.map((current) => current[1]),
      };
    });
};
