import React, { FunctionComponent } from 'react';
import { Redirect } from 'react-router-dom';
import { routePaths } from '../constants';
import { useAuth } from '../Providers';

export interface RouteLocation extends Location {
  state: { [p: string]: any };
}

export interface RouteProps {
  location?: RouteLocation;
}

export interface HomeRouteProps extends RouteProps {}

export const HomeRoute: FunctionComponent<HomeRouteProps> = ({ location }) => {
  const auth = useAuth();
  if (auth.isLoggedIn) {
    // redirect user to the org graph endpoint
    return (
      <Redirect
        to={{
          pathname: routePaths.orgMetric.constructConcretePath(
            auth.user.org_id
          ),
        }}
      />
    );
  } else {
    return (
      <Redirect
        to={{ pathname: routePaths.login.path, state: { from: location } }}
      />
    );
  }
};
