import { constructMetricsEndpoint, GetMetricsOptions } from '../getMetrics';
import { createDefaultTransport, interleaveData } from '../helpers';

export interface SymptomsData {
  sick: {
    labels: string[];
    series: number[];
  };
  stress: {
    labels: string[];
    series: number[];
  };
}

export const getSymptomsMetrics = (
  options: GetMetricsOptions
): Promise<SymptomsData> => {
  const transport = createDefaultTransport();

  const symptomSickEndpoint = constructMetricsEndpoint({
    ...options,
    metricType: 'symptom_sick' as any,
  });

  const symptomStressEndpoint = constructMetricsEndpoint({
    ...options,
    metricType: 'symptom_stress' as any,
  });

  return Promise.all([
    transport.get(symptomSickEndpoint),
    transport.get(symptomStressEndpoint),
  ]).then(([responseSick, responseStress]) => {
    const sickData = interleaveData(
      responseSick.data.data,
      responseSick.data.from,
      responseSick.data.to
    );
    const stressData = interleaveData(
      responseStress.data.data,
      responseStress.data.from,
      responseStress.data.to
    );

    return {
      sick: {
        labels: sickData.map((current) => current[0]),
        series: sickData.map((current) => current[1]),
      },
      stress: {
        labels: stressData.map((current) => current[0]),
        series: stressData.map((current) => current[1]),
      },
    };
  });
};
