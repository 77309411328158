import React from 'react';
import { Routes } from './Routes';
import { AuthProvider } from './Providers';
import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';

const theme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '.MuiTooltip-tooltip': {
          pointerEvents: 'all',
        },

        // position the chart relativel
        '.ct-chart': {
          position: 'relative',
        },

        // define stroke line width
        '.ct-series .ct-line': {
          strokeWidth: `1px`,
        },

        // define ct bar stroke width
        '.ct-series .ct-bar': {
          strokeWidth: `15px`,
        },

        //define stoke point radius
        '.ct-point': {
          strokeWidth: `6px !important`,
        },

        '.ct-point.ct-point-unique': {
          strokeWidth: `10px !important`,
          strokeLinecap: 'square',
        },

        '.ct-label.ct-horizontal.ct-end': {
          position: 'relative',
        },

        // adust x axis label, shift it towards left to center it for ct-chart-line
        '.ct-chart-line .ct-label.ct-horizontal': {
          marginLeft: '-15px',
          marginTop: '10px',
        },

        // adjst y axis label
        '.ct-label.ct-vertical': {
          marginLeft: '0px',
          marginTop: '4px',
        },

        // disable pointer events on the ct area, to sure that
        // tooltip works
        '.ct-area, .ct-line': {
          pointerEvents: 'none',
        },

        // style tooltips
        '.chartist-tooltip': {
          position: 'absolute',
          display: 'inline-block',
          minWidth: 100,
          padding: '.5em',
          fontWeight: 700,
          textAlign: 'center',
          pointerEvents: 'none',
          zIndex: 1,
          opacity: 0,
          borderRadius: '12px',
          transition: 'opacity .2s linear',
          background: '#000',
          color: '#fff',

          '&::before': {
            content: '""',
            position: 'absolute',
            top: '100%',
            left: '50%',
            width: 0,
            height: 0,
            marginLeft: `-8px`,
            border: `8px solid transparent`,
            borderTopColor: '#333',
          },
          '&.tooltip-show': {
            opacity: 1,
          },
        },
      },
    },
  },

  spacing: (value) => {
    const spacings = [0, 4, 6, 12, 16, 20, 24, 32, 40, 48, 60, 80, 100, 120];
    const multiplier = value < 0 ? -1 : 1;
    const absValue = Math.abs(value);
    if (absValue < spacings.length) {
      return multiplier * spacings[absValue];
    } else {
      return absValue * multiplier;
    }
  },
});

/**
 * the app component defines the
 */
export const App = function App() {
  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <CssBaseline />
        <Routes />
      </AuthProvider>
    </ThemeProvider>
  );
};
