import { Box } from '@material-ui/core';
import React from 'react';
import { useParams } from 'react-router-dom';
import { HabitLevelsMetrics, Metrics } from '../../../Components';
import { DateSelector } from '../../../Components/DateSelector';
import { SymptomMetrics } from '../../../Components/Metrics/SymptomMetrics';
import { RouteHeader } from '../../../Components/RouteHeader';
import { UserSelector } from '../../../Components/UserSelector';
import { MetricType } from '../../../constants';
import { useDates } from '../../../hooks/useDates';

export interface UserMetricsRouteParams {
  orgId: string;
  userId: string;
  metrics: MetricType | 'all';
}

export const UserMetricsRoute = () => {
  const { orgId, userId, metrics } = useParams() as UserMetricsRouteParams;

  const checkMetric = (value: UserMetricsRouteParams['metrics']) => {
    return metrics === value || metrics === 'all';
  };

  const { startDate, endDate } = useDates();

  // generate dates if not given in the query parameters.
  return (
    <Box>
      <RouteHeader>User Charts</RouteHeader>
      <Box
        mb={10}
        display={'flex'}
        justifyContent={'space-between'}
        flexWrap={'wrap'}
      >
        <UserSelector orgId={orgId} initialUserId={userId} metrics={metrics} />
        <DateSelector initialStartDate={startDate} initialEndDate={endDate} />
      </Box>

      {checkMetric('stress_score') && (
        <Metrics
          entity={'user'}
          metricType={'stress_score'}
          orgId={orgId}
          userId={userId}
          startDate={startDate}
          endDate={endDate}
          tooltip={{
            descriptions: [
              'This is your daily Stress Score: Great, Good, Easy, Rest, or Sick',
              'The daily Stress Score is calculated based on recent trends in biometric and behavioral data for each User.',
            ],
            readMoreLink: {
              link:
                'https://www.healthzilla.ai/science/2019/11/30/stress-score',
            },
          }}
        />
      )}

      {checkMetric('hrv') && (
        <Metrics
          entity={'user'}
          metricType={'hrv'}
          orgId={orgId}
          userId={userId}
          startDate={startDate}
          endDate={endDate}
          tooltip={{
            descriptions: [
              'Heart Rate Variability ("HRV") is the leading indicator of stress for your body.',
              'HRV is measured in milliseconds and indicates the variability in delay between your individual heartbeats',
            ],

            readMoreLink: {
              intro:
                'This data comes from the fingertip Stress Scan. You can read in-depth about HRV',
              link:
                'https://www.healthzilla.ai/science/2019/10/17/heart-rate-variability',
            },
          }}
        />
      )}

      {checkMetric('rhr') && (
        <Metrics
          entity={'user'}
          metricType={'rhr'}
          orgId={orgId}
          userId={userId}
          startDate={startDate}
          endDate={endDate}
          tooltip={{
            descriptions: [
              'Resting Heart Rate (“RHR”) is a general indicator of your recovery and overall health status',
              'This data is measured in beats-per-minute and comes from the fingertip Stress Scan.',
            ],
          }}
        />
      )}

      {checkMetric('symptoms') && (
        <SymptomMetrics
          entity={'user'}
          orgId={orgId}
          userId={userId}
          startDate={startDate}
          endDate={endDate}
          tooltip={{
            descriptions: [
              'After each Stress Scan, Users can report symptoms of Stress or Sickness',
              "You can see the selected User's symptoms below the date at the bottom of the chart.",
            ],
          }}
        />
      )}

      {checkMetric('habit_levels') && (
        <HabitLevelsMetrics
          entity={'user'}
          userId={userId}
          orgId={orgId}
          startDate={startDate}
          endDate={endDate}
          tooltip={{
            descriptions: [
              'The Level indicates how often a Habit is completed each week. Level 1 means once a week, Level 7 means every day.',
            ],
            readMoreLink: {
              link: 'https://www.healthzilla.ai/science/healthy-habits',
            },
          }}
        />
      )}
    </Box>
  );
};
