import React, { ComponentProps, FunctionComponent } from 'react';
import { useLocation } from 'react-router-dom';
import { useAuthWithDispatch } from '../../Providers';
import { Box, Button, Typography } from '@material-ui/core';
import { createLoginRedirectHandler } from '../../lib';

export const AuthButton: FunctionComponent<ComponentProps<typeof Button>> = (
  props
) => {
  const location = useLocation();
  const auth = useAuthWithDispatch();

  if (auth.isLoggedIn) {
    return (
      <Box>
        <Box pl={2}>
          <Typography color="textSecondary">{auth.user.email}</Typography>
        </Box>
        <Box pl={2} pt={2}>
          <Button
            {...props}
            variant="outlined"
            onClick={() => auth.dispatch({ type: 'logout' })}
          >
            Logout
          </Button>
        </Box>
      </Box>
    );
  } else {
    return (
      <Box>
        <Button
          {...props}
          variant="outlined"
          onClick={createLoginRedirectHandler()}
        >
          Login
        </Button>
      </Box>
    );
  }
};
