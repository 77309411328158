import {
  Box,
  Button,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import React, { FunctionComponent, useRef } from 'react';

import isEmail from 'validator/es/lib/isEmail';
import { useForceUpdate } from '../../../hooks';

interface UseStylesProps {
  hasError: boolean;
  hasSuccess: boolean;
}

const useStyles = makeStyles((theme) => {
  return {
    formBox: {
      display: 'block',
      [theme.breakpoints.up('md')]: {
        display: 'flex',
        alignItems: 'start',
      },
    },

    formControl: {
      [theme.breakpoints.up('md')]: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
      },
    },

    textField: {
      [theme.breakpoints.up('md')]: {
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    },

    button: {
      borderRadius: '50px',
    },

    messageField: {
      width: '100%',

      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),

      textAlign: 'center',

      color: (props: UseStylesProps) => {
        if (props.hasSuccess) {
          return theme.palette.info['main'];
        } else {
          return theme.palette.error.main;
        }
      },
    },
  };
});

export interface EmailFormProps {
  buttonText?: string;
  onSubmit?: (email: string) => Promise<boolean>;

  /**
   * identifies the form, used to ensure that the email id stays unique
   */
  formId: string;

  /**
   * placeholder email
   */
  emailPlaceholder?: string;

  submitSuccessMessage?: string;

  submitFailMessage?: string;
}

export const EmailForm: FunctionComponent<EmailFormProps> = ({
  onSubmit,
  formId,
  buttonText,
  emailPlaceholder,

  submitSuccessMessage = 'submitted successfully',
  submitFailMessage = 'error occurred while sending, please try again',
}) => {
  const emailRef = React.createRef<HTMLInputElement>();

  const stateRef = useRef<{
    formState: 'initial' | 'submitting' | 'error' | 'submitted';
    error: string;
    success: string;
  }>({
    formState: 'initial',
    error: '',
    success: '',
  });

  const forceUpdate = useForceUpdate();

  /**
   * returns boolean value indicating if email is valid
   */
  const validateEmail = (
    e: React.FormEvent,
    isSubmittingCall?: boolean
  ): boolean => {
    const email = emailRef.current.value;

    // reset state
    stateRef.current = {
      ...stateRef.current,
      error: '',
      success: '',
      formState: 'initial',
    };

    if (email === '') {
      if (!isSubmittingCall) {
        forceUpdate();
        return true;
      } else {
        stateRef.current.error = 'enter email';
        forceUpdate();
        return false;
      }
    }

    if (!isEmail(email)) {
      stateRef.current.error = 'please enter a valid email';
      forceUpdate();
      return false;
    }

    forceUpdate();
    return true;
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    const email = emailRef.current.value;

    if (validateEmail(e, true) && onSubmit) {
      stateRef.current.formState = 'submitting';
      forceUpdate();

      onSubmit(email)
        .then((result) => {
          if (result) {
            stateRef.current.formState = 'submitted';
            stateRef.current.success = submitSuccessMessage;
          }
        })
        .catch((err) => {
          stateRef.current.formState = 'error';
          stateRef.current.error =
            err?.response?.data?.message || submitFailMessage;
        })

        .finally(() => {
          forceUpdate();
        });
    }
  };

  const { error, formState, success } = stateRef.current;

  const classNames = useStyles({
    hasError: error !== '',
    hasSuccess: success !== '',
  });

  return (
    <form
      method={'POST'}
      action={'/'}
      className={classNames.formBox}
      onSubmit={handleSubmit}
    >
      <Box className={classNames.formControl} alignItems={'center'} flex={1}>
        <Typography component={'label'} htmlFor={formId}>
          Email:
        </Typography>

        <TextField
          id={formId}
          variant={'outlined'}
          size={'small'}
          placeholder={emailPlaceholder || 'invite@example.org'}
          onChange={validateEmail}
          inputRef={emailRef}
          className={classNames.textField}
          error={error !== ''}
        />

        {/* error or success message */}
        {(error || success) && (
          <Typography className={classNames.messageField}>
            {error || success}
          </Typography>
        )}
      </Box>

      <Button
        type={'submit'}
        variant={'contained'}
        color={'secondary'}
        className={classNames.button}
        disabled={formState === 'submitting'}
      >
        {formState === 'submitting' ? 'Processing...' : buttonText || 'Submit'}
      </Button>
    </form>
  );
};
