import {
  Box,
  ClickAwayListener,
  lighten,
  makeStyles,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { Info } from '@material-ui/icons';
import React, { FunctionComponent, useState } from 'react';
import { ChartLegend } from '../../../constants';
import { Legends } from '../../Legends';

const useStyles = makeStyles((theme) => {
  return {
    root: (legends: ChartLegend[]) => {
      let result = {};

      legends.forEach((legend) => {
        const baseClass = `.ct-series-${legend.seriesName}`;

        result[`& ${baseClass} .ct-point`] = {
          stroke: `${legend.color} !important`,
        };

        result[`& ${baseClass} .ct-point.ct-point-unique`] = {
          stroke: `${lighten(legend.color, 0.3)} !important`,
        };

        result[`& ${baseClass} .ct-line`] = {
          stroke: `${legend.color} !important`,
        };
        result[`& ${baseClass} .ct-bar`] = {
          stroke: `${legend.color} !important`,
        };
        result[`& ${baseClass} .ct-slice-donut`] = {
          stroke: `${legend.color} !important`,
        };
        result[`& ${baseClass} .ct-area`] = {
          fill: `${legend.color} !important`,
        };
      });

      return result;
    },

    infoIcon: {
      color: '#808080',
      marginLeft: theme.spacing(1),
    },

    tooltip: {
      fontSize: 18,
      zIndex: 10,
    },

    tooltipAnchor: {
      color: '#fff',
      marginLeft: theme.spacing(2),
      display: 'inline-block',
    },
  };
});

export type LoadState = 'loading' | 'loaded' | 'error';

export interface ChartChartContainerProps {
  title: string;
  loadState: LoadState;
  legends: ChartLegend[];
  tooltip?: {
    readMoreLink?: {
      intro?: string;
      linkText?: string;
      link: string;
    };

    descriptions: string[];
  };
}

export const ChartContainer: FunctionComponent<ChartChartContainerProps> = ({
  loadState,
  legends,
  title,
  children,
  tooltip,
}) => {
  const styles = useStyles(legends);

  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const toggleTooltip = (e?: any) => {
    if (e) {
      e.stopPropagation();
    }
    setOpen(!open);
  };

  const { descriptions, readMoreLink } = tooltip || { descriptions: [] };
  return (
    <Box minHeight={500} className={styles.root}>
      <Box>
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
          px={5}
          mb={7}
        >
          <Typography variant={'h5'} component={'h2'}>
            {title}{' '}
            {descriptions.length > 0 && (
              <ClickAwayListener onClickAway={handleTooltipClose}>
                <Tooltip
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  open={open}
                  className={styles.tooltip}
                  title={
                    <>
                      {descriptions.map((current, idx) => {
                        return (
                          <Box component={'p'} key={idx} fontSize={12}>
                            {current}
                          </Box>
                        );
                      })}

                      {readMoreLink && readMoreLink.link && (
                        <Box component={'p'} fontSize={12}>
                          {(readMoreLink.intro || 'Read more') + ' '}
                          <a
                            href={readMoreLink.link}
                            className={styles.tooltipAnchor}
                          >
                            {readMoreLink.linkText || 'here'}
                          </a>
                        </Box>
                      )}
                    </>
                  }
                >
                  <Box onClick={toggleTooltip} display={'inline-block'}>
                    <Info
                      style={{ fontSize: 18 }}
                      className={styles.infoIcon}
                    />
                  </Box>
                </Tooltip>
              </ClickAwayListener>
            )}
          </Typography>
          <Legends legends={legends} />
        </Box>

        {children}
      </Box>
    </Box>
  );
};
