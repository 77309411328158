import { Box, makeStyles, useTheme } from '@material-ui/core';
import React, { FunctionComponent, useCallback, useRef } from 'react';
import { useForceUpdate, useMediaQuery } from '../hooks';
import { useAuth } from '../Providers';
import { Header } from './Header';
import { Pusher } from './Pusher';

export interface LayoutProps {}

interface UseStylesProps {
  menuActive: boolean;
  initialized: boolean;
  menuWidth: number;
}

const useStyles = makeStyles((theme) => ({
  headerBox: {},

  contentBox: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    transition: 'margin-left ease 0.3s',

    [theme.breakpoints.up('lg')]: {
      marginLeft: (props: UseStylesProps) =>
        !props.initialized || props.menuActive
          ? props.menuWidth + 20
          : theme.spacing(4),
    },
  },

  pusherBox: {
    transform: (props: UseStylesProps) => {
      return `translateX(${
        !props.initialized || !props.menuActive ? -100 : 0
      }%)`;
    },

    [theme.breakpoints.up('lg')]: {
      transform: (props: UseStylesProps) =>
        `translateX(${!props.initialized || props.menuActive ? 0 : -100}%)`,
    },
  },

  viewBox: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(4),
      marginLeft: theme.spacing(4),
      marginRight: theme.spacing(4),
    },
  },
}));

export const Layout: FunctionComponent<LayoutProps> = ({ children }) => {
  const theme = useTheme();
  const auth = useAuth();
  const query = theme.breakpoints.up('lg');

  const stateRef = useRef({
    menuActive: true,
    initialized: false,
    menuWidth: 300,
  });

  const forceUpdate = useForceUpdate();

  const onChange = useCallback((matches: boolean) => {
    if (!stateRef.current.initialized) {
      stateRef.current.initialized = true;
      stateRef.current.menuActive = matches;

      forceUpdate();
    }
  }, []);

  useMediaQuery(query, {
    watchChanges: false,
    onChange,
  });

  const toggleMenu = () => {
    stateRef.current.menuActive = !stateRef.current.menuActive;
    forceUpdate();
  };

  const { initialized, menuActive, menuWidth } = stateRef.current;

  const classes = useStyles({
    initialized,
    menuActive,
    menuWidth,
  });

  return (
    <Box>
      {/* render pusher */}
      {auth.isLoggedIn && (
        <Pusher className={classes.pusherBox} width={menuWidth} />
      )}

      {/* render header */}
      <Header className={classes.headerBox} toggleMenu={toggleMenu} />

      {/* render content */}
      <Box className={classes.contentBox} px={5} pt={8} pb={10}>
        {children}
      </Box>
    </Box>
  );
};
