import { createDefaultTransport } from '../helpers';

export const getOrgTags = (orgId: string) => {
  let endPoint = `/dapi/orgs/${orgId}/tags`;

  return createDefaultTransport()
    .get(`${endPoint}`)
    .then((response) => {
      return response.data.tags as string[];
    });
};
