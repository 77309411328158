import React, { FunctionComponent, useEffect, useRef } from 'react';
import { useState } from 'react';
import { Chart, ChartData } from '../../Chart';
import { MetricsChartConfig } from '../../../constants';
import { ChartContainer, LoadState } from '../Helpers';
import { getHabitLevels } from '../../../lib/api/getHabitLevels';
import { makeStyles } from '@material-ui/core';
import { MetricsProps } from '../Metrics';

const useStyles = makeStyles({
  root: () => {
    let result = {};
    result['& svg'] = {
      overflow: 'visible',
    };

    result['& .ct-chart-bar .ct-label.ct-horizontal.ct-end'] = {
      transform: 'rotate(-60deg)',
      transformOrigin: 'bottom',
    };

    return result;
  },
});

export const HabitLevelsMetrics: FunctionComponent<MetricsProps> = ({
  orgId,
  userId,
  startDate,
  endDate,
  tags,
  tooltip,
  entity,
}) => {
  const chartOptions = MetricsChartConfig['habit_levels']({ entity });

  const dataRef = useRef<ChartData>({
    labels: [],
    series: [],
  });

  const [metricsLoadState, setMetricsLoadState] = useState<LoadState>(
    'loading'
  );

  /**
   * load habit level metrics data
   */
  useEffect(() => {
    if (metricsLoadState !== 'loading') {
      setMetricsLoadState('loading');
    }

    getHabitLevels({ orgId, userId, startDate, endDate, tags }).then((data) => {
      dataRef.current = {
        labels: data.labels,
        series: [data.series],
      };
      setMetricsLoadState('loaded');
    });
  }, [orgId, userId, startDate, endDate, tags]);

  const styles = useStyles();

  return (
    <ChartContainer
      loadState={metricsLoadState}
      title={chartOptions.title}
      legends={chartOptions.legends.slice(0, dataRef.current.series.length)}
      tooltip={tooltip}
    >
      <Chart
        type={'Bar'}
        {...dataRef.current}
        chartOptions={chartOptions}
        className={styles.root}
      />
    </ChartContainer>
  );
};
