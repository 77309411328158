import { useLocation } from 'react-router-dom';

const trim = (arg: string) => (arg ? arg.trim() : '');

export const useSearchParams = <
  Result = { [p: string]: string | string[] }
>() => {
  const location = useLocation();

  const search =
    location.search.indexOf('?') > -1
      ? location.search.split('?')[1]
      : location.search;

  return search
    .split('&')
    .map(trim)
    .reduce((acc, current) => {
      if (current) {
        const [name, value] = current.split('=').map(trim);

        if (name.lastIndexOf('[]') > -1) {
          // it is an array of vaues
          acc[name.split('[]')[0]] = value ? value.split(',').map(trim) : [];
        } else {
          acc[name] = value;
        }
      }
      return acc;
    }, {} as Result);
};
