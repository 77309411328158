import { padDate } from './padDate';

export const formatDate = (date: Date) =>
  `${date.getFullYear()}-${padDate(date.getMonth() + 1)}-${padDate(
    date.getDate()
  )}`;

export const formatDateParameters = (
  year: number,
  month: number,
  day: number
) => `${year}-${padDate(month)}-${padDate(day)}`;
