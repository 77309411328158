import React, { FunctionComponent, useEffect, useRef } from 'react';
import { useState } from 'react';
import { Chart, ChartData } from '../../Chart';
import { MetricsChartConfig } from '../../../constants';
import { ChartContainer, LoadState } from '../Helpers';
import { MetricsProps } from '../Metrics';
import { getSymptomsMetrics, UserSymptomsData } from '../../../lib';
import { useForceUpdate } from '../../../hooks';
import { makeStyles } from '@material-ui/core';

export interface SymptomMetricsProps extends MetricsProps {}

const useStyles = makeStyles({
  root: () => {
    let result = {};
    result['& svg'] = {
      overflow: 'visible',
    };

    return result;
  },
});

export const SymptomMetrics: FunctionComponent<SymptomMetricsProps> = ({
  orgId,
  userId,
  startDate,
  endDate,
  tags,
  tooltip,
  entity,
}) => {
  const dataRef = useRef<ChartData>({
    labels: [],
    series: [],
  });

  const userDataRef = useRef<UserSymptomsData>({
    sick: {
      labels: [],
      series: [],
    },
    stress: {
      labels: [],
      series: [],
    },
  });

  const [metricsLoadState, setMetricsLoadState] = useState<LoadState>(
    'loading'
  );

  const forceUpdate = useForceUpdate();

  // load user data
  useEffect(() => {
    if (userId) {
      getSymptomsMetrics({ orgId, userId, startDate, endDate, tags }).then(
        (result) => {
          userDataRef.current = result;

          // we need a way to redraw the chart.
          dataRef.current = {
            labels: [...dataRef.current.labels],
            series: dataRef.current.series,
          };
          forceUpdate();
        }
      );
    }
  }, [orgId, userId, startDate, endDate, tags]);

  useEffect(() => {
    if (metricsLoadState !== 'loading') {
      setMetricsLoadState('loading');
    }
    getSymptomsMetrics({ orgId, startDate, endDate, tags }).then((result) => {
      dataRef.current = {
        labels: result.sick.labels,
        series: [result.sick.series, result.stress.series],
      };
      setMetricsLoadState('loaded');
    });
  }, [orgId, startDate, endDate, tags]);

  const defaultChartOpts = MetricsChartConfig.symptoms({ entity });
  const chartOptions = {
    ...defaultChartOpts,
    xAxisInterpolationFunc: (value, index) => {
      const label = defaultChartOpts.xAxisInterpolationFunc(value, index);
      return `${label}\n${
        userDataRef.current.sick.series[index] ? 'Sick' : ''
      }\n${userDataRef.current.stress.series[index] ? 'Stress' : ''}`;
    },
  };

  // our indicator of sick or stressed employee
  chartOptions.pointClassNamesGenerator = (seriesIndex, index, series) => {
    const userData = userDataRef.current;
    let targetSeries: number[];

    switch (seriesIndex) {
      case 0:
        targetSeries = userData.sick.series;
        break;
      case 1:
        targetSeries = userData.stress.series;
    }

    if (targetSeries.length && targetSeries[index] === 1) {
      return `ct-point-unique`;
    }
    return null;
  };

  const styles = useStyles();

  return (
    <ChartContainer
      loadState={metricsLoadState}
      title={chartOptions.title}
      legends={chartOptions.legends.slice(
        0,
        userId ? undefined : dataRef.current.series.length
      )}
      tooltip={tooltip}
    >
      <Chart
        {...dataRef.current}
        type={'Bar'}
        chartOptions={chartOptions}
        className={styles.root}
      />
    </ChartContainer>
  );
};
