import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { Box, BoxProps, makeStyles } from '@material-ui/core';
import { useForceUpdate } from '../../hooks';
import { useHistory, useLocation } from 'react-router-dom';
import {
  concatQueryString,
  getOrgUsers,
  OrgUser,
  parseQueryString,
} from '../../lib';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { MetricType, routePaths } from '../../constants';
import { LoadState } from '../Metrics';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export interface UserSelectorProps extends BoxProps {
  orgId: string;
  initialUserId: string;
  metrics: MetricType | 'all';
}

// you have to generate it and supply it
export const UserSelector: FunctionComponent<UserSelectorProps> = ({
  orgId,
  initialUserId,
  metrics,
  ...props
}) => {
  const orgUsersRef = useRef<OrgUser[]>([]);
  const [orgUsersLoadState, setOrgUsersLoadState] = useState<LoadState>(
    'loading'
  );

  // we rely on the fact that the initialUser changes when we push to the router,
  // to keep the widget in sync, we need to store our state in refs, and watch for changes.

  const selectedUserRef = useRef<typeof initialUserId>('');

  const forceUpdate = useForceUpdate();

  const location = useLocation();
  const history = useHistory();

  const handleChange = (event) => {
    selectedUserRef.current = event.target.value;

    const queries = parseQueryString(location.search);

    // by pushing to the router, we expect that the initial user id will change, and get handled accordingly
    history.push(
      `${routePaths.userMetric.constructConcretePath(
        orgId,
        selectedUserRef.current,
        metrics
      )}?${concatQueryString(queries)}`
    );
  };

  // watch for changes in user id
  useEffect(() => {
    selectedUserRef.current = initialUserId;
    forceUpdate();
  }, [initialUserId]);

  // watch for changes in orgId
  useEffect(() => {
    if (orgUsersLoadState !== 'loading') {
      setOrgUsersLoadState('loading');
    }

    getOrgUsers(orgId).then((result) => {
      orgUsersRef.current = result;
      setOrgUsersLoadState('loaded');
    });
  }, [orgId]);

  const classes = useStyles();

  return (
    <Box {...props}>
      <FormControl className={classes.formControl}>
        <InputLabel id="user-selector">Displayed User</InputLabel>
        <Select
          labelId="user-selector"
          value={selectedUserRef.current}
          onChange={handleChange}
        >
          {orgUsersRef.current.map((current) => {
            return (
              <MenuItem value={current.id} key={current.id}>
                {current.name} ({current.email})
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  );
};
