import { useLocation } from 'react-router-dom';

export const useIsActivePath = (path: string) => {
  const location = useLocation();

  const queryIndex = path.indexOf('?');
  if (queryIndex !== -1) {
    path = path.substring(0, queryIndex);
  }

  const hashIndex = path.indexOf('#');
  if (hashIndex !== -1) {
    path = path.substring(0, hashIndex);
  }

  return location.pathname === path;
};
