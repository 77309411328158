import React, { FunctionComponent } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { Box, Hidden, IconButton, Typography } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import cx from 'classnames';
import { useAuth } from '../../Providers';

const useStyles = makeStyles((theme) => ({
  logo: {
    width: 42,
    marginRight: 8,
  },

  toolbar: {
    height: 64,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(3),
    marginLeft: theme.spacing(3),
  },

  toolbarSection: {
    display: 'flex',
    marginTop: 0,
    marginBottom: 0,
    height: '100%',
    alignItems: 'center',
  },

  toolbarSectionItem: {
    display: 'flex',
    marginTop: 0,
    marginBottom: 0,
    height: '100%',
    alignItems: 'center',
  },

  root: {
    flexGrow: 1,
  },

  menuButton: {
    marginRight: theme.spacing(3),
  },

  title: {
    // flexGrow: 1,
  },
}));

/**
 * maintain query string parameters when user is navigation
 */
export interface HeaderProps {
  className: string;

  toggleMenu: () => void;
}

export const Header: FunctionComponent<HeaderProps> = ({
  toggleMenu,
  className,
}) => {
  const classes = useStyles();

  const auth = useAuth();

  return (
    <>
      <AppBar>
        <Toolbar className={cx(className, classes.toolbar)}>
          {/* section */}
          <Box className={classes.toolbarSection}>
            {/* menu button */}
            {auth.isLoggedIn && (
              <IconButton
                edge="start"
                className={classes.menuButton}
                color="inherit"
                aria-label="menu"
                onClick={toggleMenu}
              >
                <MenuIcon />
              </IconButton>
            )}

            {/* brand logo */}
            <img className={classes.logo} src="/healthzilla_dino_192.png"></img>

            {/* brand title */}
            <Hidden smUp>
              <Typography className={classes.title} variant={'h6'}>
                Healthzilla
              </Typography>
            </Hidden>
            <Hidden xsDown>
              <Typography className={classes.title} variant={'h6'}>
                Healthzilla Dashboard
              </Typography>
            </Hidden>
          </Box>
        </Toolbar>
      </AppBar>
      <Toolbar />
    </>
  );
};
