import { routePaths } from '../../constants';

export const createLoginRedirectHandler = () => (
  e?: React.MouseEvent<any>
) => {
  window.location = routePaths.login.path as any;
};

export const createSignupRedirectHandler = () => (
  e?: React.MouseEvent<any>
) => {
  window.location = routePaths.signup.path as any
};
