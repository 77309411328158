import { Box, makeStyles, Typography } from '@material-ui/core';
import React, { ComponentProps, FunctionComponent } from 'react';
import { ChartLegend } from '../../constants';

const useLegendStyle = makeStyles({
  dot: (props: ChartLegend) => {
    return {
      display: 'inline-block',
      width: 10,
      height: 10,
      backgroundColor: props.color,
      marginRight: 8,
      borderRadius: props.shape === 'square' ? '0%' : '50%',
    };
  },
});

export interface LegendProps {
  legend: ChartLegend;
}
const Legend: FunctionComponent<LegendProps & ComponentProps<typeof Box>> = ({
  legend,
  ...props
}) => {
  const styles = useLegendStyle(legend);
  return (
    <Box component={'li'} {...props} fontWeight={'medium'}>
      <span className={styles.dot} />
      <Typography component={'span'}>{legend.label}</Typography>
    </Box>
  );
};

const useLegendsStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: 0,
    padding: 0,
    listStyle: 'none',
  },
});

export interface LegendsProps {
  legends: ChartLegend[];
}

export const Legends: FunctionComponent<LegendsProps> = ({ legends }) => {
  const style = useLegendsStyles();
  return (
    <Box component={'ul'} className={style.root}>
      {legends.map((current, index) => {
        return <Legend legend={current} ml={index > 0 ? 5 : 0} key={index} />;
      })}
    </Box>
  );
};
