import {
  Box,
  Hidden,
  IconButton,
  makeStyles,
  TextField,
} from '@material-ui/core';
import React, { createRef, FunctionComponent, useMemo, useState } from 'react';
import Chip from '@material-ui/core/Chip';
import { OrgUser } from '../../../../lib';
import { Add, Close } from '@material-ui/icons';
import Autocomplete from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles((theme) => {
  return {
    boxContainer: {
      [theme.breakpoints.up('md')]: {
        display: 'flex',
      },
    },

    emailBox: {
      wordBreak: 'break-all',
    },

    tagsBox: {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
      marginTop: theme.spacing(5),
      [theme.breakpoints.up('md')]: {
        marginTop: 0,
      },
    },

    tag: {
      marginBottom: theme.spacing(2),
      marginRight: theme.spacing(2),
    },

    addButton: {
      marginBottom: theme.spacing(2),
    },

    removeButton: {},

    tagForm: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: theme.spacing(2),
    },

    tagTextField: {
      width: 140,
      '& input': {
        paddingTop: '1px',
        paddingBottom: '1px',
      },
    },
  };
});

export interface TaggedUserProps {
  user: OrgUser;

  orgTags: string[];

  saveUserTags: (user: OrgUser, tags: string[]) => Promise<boolean>;
}

export const TaggedUser: FunctionComponent<TaggedUserProps> = ({
  user,
  saveUserTags,
  orgTags,
}) => {
  const classNames = useStyles();

  const commitTags = (tags: string[]) => {
    if (saveUserTags) {
      return saveUserTags(user, tags);
    } else {
      return Promise.resolve(true);
    }
  };

  const deleteTag = (index) => {
    const tags = user.tags.filter(
      (current, currentIndex) => currentIndex !== index
    );
    commitTags(tags);
  };

  const inputRef = createRef<HTMLInputElement>();

  const [canAddTag, setCanAddTag] = useState(false);

  const addTag = (value: string) => {
    const tag = value.trim();
    if (tag) {
      if (user.tags.find((current) => current === tag)) {
        return Promise.resolve(true);
      }

      return commitTags(user.tags.concat(tag)).then(() => {
        return true;
      });
    } else {
      return Promise.resolve(false);
    }
  };

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    const input = inputRef.current;
    addTag(input.value).then((added) => {
      if (added) {
        input.value = '';
      }
    });
  };

  const onSelect = (
    event,
    value: string,
    reason: 'select-option' | 'create-option'
  ) => {
    const input = inputRef.current;
    switch (reason) {
      case 'select-option':
        addTag(value).then((result) => {
          if (result && input) {
            input.value = '';
          }
        });
        break;
    }
  };

  const selectOptions = useMemo(() => {
    const hash = {};
    for (const tag of user.tags) {
      hash[tag] = 1;
    }

    return orgTags.filter((current) => typeof hash[current] === 'undefined');
  }, [orgTags, user.tags]);

  return (
    <Box className={classNames.boxContainer} mb={8}>
      <Box width={250} maxWidth={250} className={classNames.emailBox}>
        {user.email}
      </Box>

      <Box flex={1} pl={[0, 0, 3]} className={classNames.tagsBox}>
        {user.tags.length > 0 &&
          user.tags.map((tag, tagIndex) => {
            return (
              <Chip
                label={tag}
                key={tag}
                onDelete={() => deleteTag(tagIndex)}
                className={classNames.tag}
                size={'small'}
                color={'primary'}
              />
            );
          })}

        {user.tags.length === 0 && (
          <Chip label={'No tags'} className={classNames.tag} />
        )}

        {!canAddTag && (
          <IconButton
            size={'small'}
            className={classNames.addButton}
            onClick={() => setCanAddTag(true)}
          >
            <Add />
          </IconButton>
        )}

        {canAddTag && (
          <form
            method={'post'}
            className={classNames.tagForm}
            onSubmit={onSubmit}
          >
            <Autocomplete
              options={selectOptions}
              // autoComplete
              // autoSelect
              onChange={onSelect}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    variant={'outlined'}
                    placeholder={'tag'}
                    inputRef={inputRef}
                    size={'small'}
                    className={classNames.tagTextField}
                  />
                );
              }}
            />
            {/* <TextField
              variant={'outlined'}
              placeholder={'tag'}
              inputRef={inputRef}
              size={'small'}
              className={classNames.tagTextField}
            /> */}

            <Hidden xsUp>
              <button type={'submit'} name={'submit'} />
            </Hidden>

            <IconButton
              component={'button'}
              size={'small'}
              className={classNames.removeButton}
              onClick={() => setCanAddTag(false)}
            >
              <Close />
            </IconButton>
          </form>
        )}
      </Box>
    </Box>
  );
};
