import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { parseQueryString } from '../lib';
import { useForceUpdate } from './useForceUpdate';

const processDates = (startDate: string, endDate: string) => {
  let parsedStartDate: Date;
  let parsedEndDate: Date;

  // resolve start date
  if (startDate && /(\d{4})-(\d{1,2})-(\d{1,2})/.test(startDate)) {
    parsedStartDate = new Date(
      parseInt(RegExp.$1, 10),
      parseInt(RegExp.$2, 10) - 1,
      parseInt(RegExp.$3, 10)
    );
  } else {
    parsedStartDate = new Date();
  }

  // resolve end date
  if (endDate && /(\d{4})-(\d{1,2})-(\d{1,2})/.test(endDate)) {
    parsedEndDate = new Date(
      parseInt(RegExp.$1, 10),
      parseInt(RegExp.$2, 10) - 1,
      parseInt(RegExp.$3, 10)
    );
  } else {
    // default to 7 days interval
    parsedEndDate = new Date(
      parsedStartDate.getTime() - 7 * 24 * 60 * 60 * 1000
    );
  }

  // check for differences
  if (parsedStartDate > parsedEndDate) {
    const interchanger = parsedStartDate;
    parsedStartDate = parsedEndDate;
    parsedEndDate = interchanger;
  }

  return {
    startDate: parsedStartDate,
    endDate: parsedEndDate,
  };
};

/**
 * it picks the dates from the querystrings if found
 */
export const useDates = () => {
  const location = useLocation();

  const queries = parseQueryString(location.search);

  // pick values from the url
  const startDate = queries.get('from');
  const endDate = queries.get('to');

  const stateRef = useRef<{ startDate: Date; endDate: Date }>(
    processDates(startDate, endDate)
  );

  const forceUpdate = useForceUpdate();

  const isFirstMountRef = useRef(true);

  useEffect(() => {
    if (isFirstMountRef.current) {
      isFirstMountRef.current = false;
      return;
    }

    stateRef.current = processDates(startDate, endDate);
    forceUpdate();
  }, [startDate, endDate]);

  return stateRef.current;
};
