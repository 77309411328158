import { ChartistPluginAddExtraClassNamesOptions } from './lib';

export type MetricType =
  | 'hrv'
  | 'stress_score'
  | 'rhr'
  | 'symptoms'
  | 'stress_score_count'
  | 'habit_levels';

export type MetricEntity = 'user' | 'org';

export const LoginTokenStoreName = 'login-token';

export const IS_BROWSER = typeof window !== 'undefined';

// route paths
export const routePaths = {
  /**
   * home page
   */
  home: {
    path: '/',
  },

  /**
   * login route basically leads user to the external login page
   */
  login: {
    path: '/login',
  },

  signup: {
    path: '/signup',
  },

  /**
   * auth route receives the returned auth token, and stores it, then redirect user to the appropriate page
   */
  auth: {
    path: '/auth',
  },

  help: {
    path: '/help',
  },

  /**
   * allows user to view organisation only data
   */
  orgMetric: {
    path: '/metrics/:metrics/orgs/:orgId',
    constructConcretePath: (
      orgId: string,
      metrics: MetricType | 'all' = 'all',
      currentQueryString: string = ''
    ) => {
      return `/metrics/${metrics}/orgs/${orgId}${currentQueryString}`;
    },
  },

  /**
   * allows user to view and compare his/her data with his organisation level data
   */
  userMetric: {
    path: '/metrics/:metrics/orgs/:orgId/users/:userId',
    constructConcretePath: (
      orgId: string,
      userId: string,
      metrics: MetricType | 'all' = 'all',
      currentQueryString: string = ''
    ) => {
      return `/metrics/${metrics}/orgs/${orgId}/users/${userId}${currentQueryString}`;
    },
  },

  /**
   * returns the org settings path
   */
  orgSettings: {
    path: '/orgs/:orgId/settings',
    constructConcretePath: (orgId) => {
      return `/orgs/${orgId}/settings`;
    },
  },

  /**
   * org users listings
   */
  orgUsers: {
    path: '/orgs/:orgId/users',
    constructConcretePath: (orgId) => {
      return `/orgs/${orgId}/users`;
    },
  },
};

// picked from chartist website
export interface ChartistAxisExtraProps {
  // If high is specified then the axis will display values explicitly up to this value and the computed maximum from the data is ignored
  high?: number;
  // If low is specified then the axis will display values explicitly down to this value and the computed minimum from the data is ignored
  low?: number;
  // This option will be used when finding the right scale division settings. The amount of ticks on the scale will be determined so that as many ticks as possible will be displayed, while not violating this minimum required space (in pixel).
  scaleMinSpace?: number;
  // Can be set to true or false. If set to true, the scale will be generated with whole numbers only.
  onlyInteger?: boolean;
  // The reference value can be used to make sure that this value will always be on the chart. This is especially useful on bipolar charts where the bipolar center always needs to be part of the chart.
  referenceValue?: number;
}

// chart legend
export interface ChartLegend {
  seriesName: string;
  label: string;
  color: string;
  shape?: 'circle' | 'square';
}

export interface ChartAxisLabelInterpolationFunc {
  (value: number | string, index?: number): number | string;
}

export interface MetricChartConfig
  extends ChartistPluginAddExtraClassNamesOptions {
  // chart title
  title: string;

  // define label interpolation functions
  yAxisInterpolationFunc?: ChartAxisLabelInterpolationFunc;
  xAxisInterpolationFunc?: ChartAxisLabelInterpolationFunc;

  // define legends
  legends: ChartLegend[];

  // define extra yaxis props
  yAxisExtraProps?: ChartistAxisExtraProps;

  // define extra xaxis props
  xAxisExtraProps?: ChartistAxisExtraProps;
}

export type MetricChartConfigGenerator = (options?: {
  entity: MetricEntity;
}) => MetricChartConfig;

const getDefaultLegends = (
  orgLabel: string,
  userLabel?: string
): ChartLegend[] => {
  return [
    {
      seriesName: 'a',
      label: orgLabel,
      color: '#006400',
    },
    {
      seriesName: 'b',
      label: userLabel,
      color: '#FF8C00',
    },
  ];
};

const defaultXAxisInterpolationFunc: ChartAxisLabelInterpolationFunc = (
  value
) => {
  const regex = /^\d+-(\d+)-(\d+)$/;
  if (regex.test(value.toString())) {
    return `${RegExp.$2}.${RegExp.$1}`;
  } else {
    return value;
  }
};

export const MetricsChartConfig: {
  [p in MetricType]: MetricChartConfigGenerator;
} = {
  hrv: () => ({
    title: 'Heart Rate Variability',
    legends: getDefaultLegends('Org Average (ms)', 'User (ms)'),
    xAxisInterpolationFunc: defaultXAxisInterpolationFunc,
    yAxisExtraProps: {
      onlyInteger: true,
    },
  }),

  rhr: () => ({
    title: 'Resting Heart Rate',
    legends: getDefaultLegends('Org Average (bpm)', 'User (bpm)'),
    xAxisInterpolationFunc: defaultXAxisInterpolationFunc,
    yAxisExtraProps: {
      onlyInteger: true,
    },
  }),

  stress_score: () => ({
    title: 'Stress Score',
    legends: getDefaultLegends('Org Average', 'User Stress Score'),

    xAxisInterpolationFunc: defaultXAxisInterpolationFunc,

    yAxisInterpolationFunc: (value) => {
      switch (value) {
        case 1:
          return 'Sick';

        case 2:
          return 'Rest';

        case 3:
          return 'Easy';

        case 4:
          return 'Good';

        case 5:
          return 'Great';
      }
    },

    yAxisExtraProps: {
      low: 1,
      high: 5,
      onlyInteger: true,
      scaleMinSpace: 1,
    },
  }),

  stress_score_count: () => ({
    title: 'Stress Score Counts',

    xAxisInterpolationFunc: defaultXAxisInterpolationFunc,
    yAxisExtraProps: {
      onlyInteger: true,
    },
    legends: [
      {
        seriesName: 'a',
        label: 'Sick',
        color: '#FF0000',
      },
      {
        seriesName: 'b',
        label: 'Rest',
        color: '#FFA500',
      },
      {
        seriesName: 'c',
        label: 'Easy',
        color: '#dfcd34',
      },
      {
        seriesName: 'd',
        label: 'Good',
        color: '#32CD32',
      },
      {
        seriesName: 'e',
        label: 'Great',
        color: '#008000',
      },
    ],
  }),

  symptoms: () => ({
    title: 'Self-Reported Symptoms',

    xAxisInterpolationFunc: defaultXAxisInterpolationFunc,

    legends: [
      {
        seriesName: 'a',
        label: 'Org Sick Total',
        color: '#800000',
      },
      {
        seriesName: 'b',
        label: 'Org Stress Total',
        color: '#FF8C00',
      },
      // {
      //   seriesName: 'c',
      //   label: 'User Reported Sick',
      //   color: '#000',
      //   shape: 'square',
      // },
    ],

    yAxisExtraProps: {
      onlyInteger: true,
    },
  }),

  habit_levels: ({ entity }) => {
    return {
      title: 'Habit Levels',
      legends: [
        {
          seriesName: 'a',
          label: entity === 'user' ? 'User Level' : 'Org Average Level',
          color: '#558855',
        },
      ],
      yAxisExtraProps: {
        onlyInteger: true,
        low: 0,
        high: 7,
      },
    };
  },
};
