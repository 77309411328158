import { constructMetricsEndpoint, GetMetricsOptions } from '../getMetrics';
import { createDefaultTransport } from '../helpers';

export const getOrgStressScoreCount = (options: GetMetricsOptions) => {
  const transport = createDefaultTransport();

  const endpoint = constructMetricsEndpoint({
    ...options,
    metricType: 'stress_score_count',
  });

  return transport.get(endpoint).then((response) => {
    const { data } = response.data;

    return {
      sick: {
        labels: data.map((current) => current[0]),
        series: data.map((current) => current[1]),
      },
      rest: {
        labels: data.map((current) => current[0]),
        series: data.map((current) => current[2]),
      },
      easy: {
        labels: data.map((current) => current[0]),
        series: data.map((current) => current[3]),
      },
      good: {
        labels: data.map((current) => current[0]),
        series: data.map((current) => current[4]),
      },
      great: {
        labels: data.map((current) => current[0]),
        series: data.map((current) => current[5]),
      },
    };
  });
};
