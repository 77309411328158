import React, { ComponentProps, FunctionComponent } from 'react';
import { useLocation } from 'react-router-dom';
import { useAuthWithDispatch } from '../../Providers';
import { Box, Button } from '@material-ui/core';
import { createSignupRedirectHandler } from '../../lib';

export const SignupButton: FunctionComponent<ComponentProps<typeof Button>> = (
  props
) => {
  const location = useLocation();
  const auth = useAuthWithDispatch();

  if (!auth.isLoggedIn) {
    return (
      <Box>
        <Button
          {...props}
          variant="outlined"
          onClick={createSignupRedirectHandler()}
        >
          Sign up
        </Button>
      </Box>
    );
  } else {
    return null;
  }
};
