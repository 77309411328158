import { Box, Typography } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import { Redirect } from 'react-router-dom';
import { routePaths } from '../constants';
import { useAuth } from '../Providers';

export interface RouteLocation extends Location {
  state: { [p: string]: any };
}

export interface RouteProps {
  location?: RouteLocation;
}

const Space = Box;

const P = ({ children, ...props }) => (
  <Box component="p" my={2} fontSize="body2.fontSize" {...props}>
    {children}
  </Box>
);

const H5 = ({ children, ...props }) => (
  <Typography {...props} variant="h5">
    {children}
  </Typography>
);

const H6 = ({ children, ...props }) => (
  <Typography {...props} variant="h6">
    {children}
  </Typography>
);

export interface HelpRouteProps extends RouteProps {}

const HelpSection = () => (
  <Box maxWidth={600}>
    <P fontSize="body1.fontSize">
      We’re delighted to have you onboard and eager to help you understand your
      clients’ wellbeing, recovery, and habits better to help them achieve their
      personal wellbeing goals.
    </P>
    <Space mt={3} />
    <H5>Here are the first steps in your Healthzilla journey:</H5>
    <Space mt={3} />

    <H6>1: Getting Started</H6>
    <P>
      Now that you’ve successfully registered and opened up your personal
      dashboard, you can find further instructions on how to make the most out
      of it from the{' '}
      <a
        href="https://www.healthzilla.ai/dashboard-quick-guide"
        target="_guide"
      >
        Dashboard Quick Guide
      </a>
      .
    </P>
    <Space mt={3} />

    <H6>2: Test the App for yourself</H6>
    <P>
      To know what your clients will see and do, it makes sense to try it first
      so you can see it all in action. First, use a different email address than
      the one you used to sign up, and invite that email via the Org Settings
      page. Open the invite email and click accept, then download the app for
      iOS or Android, and use the same email to register to the app (use the
      Email Login option, not Apple or Facebook). Once you have the app
      installed, complete your first Stress Scan, and remember to allow sharing
      data, otherwise it won't show in your Dashboard! Now you can go back to
      your Dashboard, and you should see some data in User Charts!
    </P>
    <Space mt={3} />

    <H6>3: Invite some Users!</H6>
    <P>
      Invite your clients by following the Quick Guide instructions (slide 5).
      Let your client know about the program so they expect the email invite.
      Below is a template email you can use to chat about Healthzilla with the
      client. You can also use parts of it to communicate with your client via
      text messages, or WhatsApp.
    </P>
    <Space mt={3} />

    <H6>4: Wait for some data</H6>
    <P>
      Give your clients a few days to accept the invite and download the app.
      You will see their data on your dashboard as soon as they’ve accepted the
      invitation, downloaded the Healthzilla app, completed the onboarding using
      the same email you used to invite them, and taken the first measurement
      (or synced their wearable device) – i.e. as soon as they’ve started to
      generate data.
    </P>
    <P>
      If you don’t see any data after a few days, please send a friendly
      reminder to your client to accept the invite and complete the onboarding
      using the same email address. Your clients can find a 7-minute App
      Onboarding and Features overview video instruction through{' '}
      <a href="https://www.youtube.com/watch?v=UMmyMdjMDe0" target="_tube">
        this link
      </a>{' '}
      and a complete user manual over{' '}
      <a
        href="https://drive.google.com/file/d/1Zw-pv3UR59iW7a2UukdxGoEcTdBsu-Vj/view"
        target="_manual"
      >
        here
      </a>
      .
    </P>
    <Space mt={3} />

    <H6>5: Daily check-in</H6>
    <P>
      Check out the dashboard regularly to understand your clients’ recovery,
      habits, and response to training, and utilize the data for regular
      in-depth conversations and check-ins with your client, and adjustment of
      their workout programs to optimize their results. You can find more
      inspiration from this 7-minute{' '}
      <a
        href="https://www.youtube.com/watch?v=XpMXY_EK4sU&feature=youtu.be"
        target="_tube"
      >
        demo video
      </a>
      .
    </P>
    <P>
      Should you have any questions or concerns don’t hesitate to{' '}
      <a href="https://www.healthzilla.ai/support" target="_contact">
        contact us
      </a>{' '}
      and we’ll do our best to help you out.
    </P>
    <Space mt={3} />

    <Box mt={10}>
      <P>Template email for your client:</P>
      <Space mt={3} />

      <Box ml={4}>
        <P>Hi [client]!</P>
        <Space mt={3} />

        <P>
          We are about to start using the Healthzilla wellness platform to see
          how we can use health & wellness data to improve your training results
          and build a healthier lifestyle for you. You can read more about the
          platform on their{' '}
          <a href="https://www.healthzilla.ai/forhealthandfitness">website</a>.
        </P>
        <P>
          In order for us to get started, I will send you an invite via email
          and request your consent for sharing your personal data with me. With
          your permission, I will be able to see how you progress and what your
          recovery status looks like on a daily basis. This will help me to
          optimize your training and help you reach your health goals more
          effectively.
        </P>
        <P>
          Once you have given your permission to share your data, you will be
          asked to download the Healthzilla app and start using it (if you
          haven’t done so already). There is a link in the invitation email that
          takes you to either App Store or Google Play depending on whether you
          are an iPhone or Android user respectively. Once you have downloaded
          the app, make sure you login with the same email address to which you
          received the invitation. The onboarding itself should only take 1-2
          minutes for you to finish.
        </P>
        <P>
          If needed, you can always check more detailed{' '}
          <a href="https://drive.google.com/file/d/1Zw-pv3UR59iW7a2UukdxGoEcTdBsu-Vj/view">
            instructions
          </a>{' '}
          or this
          <a href="https://www.youtube.com/watch?v=UMmyMdjMDe0">video</a> on how
          to complete the onboarding and start generating data.
        </P>
        <P>
          The important part for us is to establish a routine so try remember to
          use the app regularly - preferably every day - to see what your daily
          recovery status (Stress Score) looks like and what’s your personal
          recommendation for each day. Please don’t worry too much if you forget
          to do this one day when life is simply too busy, though. We are mainly
          interested in long-term trends so missing one day occasionally will
          not make a huge difference!
        </P>
        <P>
          I warmly welcome any feedback and suggestions you have once you start
          using Healthzilla. I also wish to thank you already at this stage for
          your participation - your genuine interest in better recovery and
          results makes a real difference in my work!
        </P>
        <P>With best wishes,</P>
        <P>[Your name]</P>
      </Box>
    </Box>
  </Box>
);

export const HelpRoute: FunctionComponent<HelpRouteProps> = ({ location }) => {
  const auth = useAuth();
  if (auth.isLoggedIn) {
    return <HelpSection />;
  } else {
    return (
      <Redirect
        to={{ pathname: routePaths.login.path, state: { from: location } }}
      />
    );
  }
};
